import { ChatHeader } from '../components/ChatHeader'
import { ChatMessages } from '../components/ChatMessages'
import { ChatInteractionBox } from '../components/ChatInteractionBox'

const CantLoginPage = function () {

    return (
        <div className="chat-container">
            <ChatHeader />
            <ChatMessages messages={[]} />
            <ChatInteractionBox dsiabled />
        </div>
    );
}

export { CantLoginPage }