import { useEffect, useRef } from 'react';
import { Zoom } from 'react-awesome-reveal';

import { useApp } from '../app-context';

import { TSPage } from '../components/commons';

const ChatMsgPage = function (props) {
    const popupRef = useRef();
    useEffect(() => {
        popupRef.current?.querySelector("button")?.focus();
    }, []);

    const [, dispatcher] = useApp();

    const handleClose = function (e) {
        e.preventDefault();
        dispatcher({ action: "closeOverlay", params: "chatMenu" });
    };

    return (
        <TSPage name={props.name} className={props.className} onClick={handleClose}>
            <Zoom duration="350">
                <div className="popupMsg" ref={popupRef}>
                    {props.children}
                </div>
            </Zoom>
        </TSPage>
    );
}

export { ChatMsgPage }
