const PlansData = {
    plans: [],
    covered: [
        { id: "injuries", title: "פציעות", desc: "בליעת חפץ? הכשת נחש? נשיכת גנב או טריקת דלת על זנב? כבר לא צריך לבחור בין עירוי לצילום, חיותא אתך מאבחון עד שיקום." },
        { id: "bloodtests", title: "בדיקות דם", desc: "כמו ספירת דם או כימיה מלאה. תפקודי כבד ותריס וגם קרישה. לפני ואחרי ניתוחים – לעיתים גם מספר פעמים.", conditions: ["illness"] },
        { id: "bloodtests", title: "בדיקות דם", desc: "במקרה של תאונה, אם יש צורך בספירת דם או כימיה מלאה. לפני ואחרי ניתוחים – לעיתים גם מספר פעמים.", conditions: ["accidents"] },
        { id: "operations", title: "ניתוחים", desc: "כמו תיקון רצועה, פריצות דיסק, הסרת גידולים, החלפת מפרקים, הוצאת אבני כליה או היפוך קיבה.", conditions: ["illness"] },
        { id: "operations", title: "ניתוחים", desc: "כמו תיקון רצועה במקרה של פציעה, הוצאת חפץ זר שנבלע ולא נשלף, גם פלטינות אחרי דריסה.", conditions: ["accidents"] },
        { id: "hospitalization", title: "אשפוזים", desc: "השגחה רפואית דרושה לעיתים בעקבות תאונה או מחלה. ימי אשפוז הם יקרים וכוללים גם תרופות ובדיקות.", conditions: ["illness"] },
        { id: "hospitalization", title: "אשפוזים", desc: "השגחה רפואית דרושה לעיתים בעקבות תאונה. ימי אשפוז הם יקרים וכוללים גם תרופות ובדיקות.", conditions: ["accidents"] },
        { id: "lab", title: "בדיקות מעבדה", desc: "כמו תרבית ורגישות, פתולוגיה או גללים. בלא אבחון נכון גם אירועים פשוטים יכולים להיות מסוכנים.", conditions: ["illness"] },
        { id: "imaging", title: "בדיקות הדמיה", desc: "דימום? פריצת דיסק? אין פסק דין מפורש? חיותא אתך עם כל אבחון שנדרש. בלי שיקול של מה כדאי, רנטגן או MRI." },
        { id: "illness", title: "מחלות", desc: "כמו תולעים או גידולים, דלקות עיניים או פרקים. הקאות ושלשולים.", conditions: ["illness"] },
        { id: "expert", title: "רפואת מומחים", desc: "עמוד שדרה ומערכת עצבים – הם עניינים מורכבים. לכך דרושים רופאים מומחים." },
        { id: "emergency", title: "טיפול חירום", desc: "אירועים רבים מצריכים ביקורים דחופים בשעות הקטנות אליהם נלווים בדיקות וטיפולים." },
        { id: "cancer", title: "טיפולי סרטן", desc: "הסרת גידולים בהליכים חדשניים – מצילה חיים. עם חיותא מסתיימים השיקולים.", conditions: ["illness"] },
        { id: "chronic", title: "מצבים כרוניים", desc: "אלרגיה או מצב סוכרתי?  גם במלחמת התשה, חיותא פה, לתת כתף נחושה.", conditions: ["illness"] },

    ],
    notCovered: [
        { id: "fight", title: "קרבות כלבים", desc: "חיותא דואגת לכל כלבי ארצנו, בריונות רחוב – לא בבית ספרנו!" },
        { id: "cosmetic", title: "טיפול קוסמטי", desc: "גזירת זנב או קיצוצי אוזניים? מה עם לק בציפורניים? חיותא חרטה על הדגל \"תנו לחיות לחיות\". אנו מכסים רק תופעות טבעיות." },
        { id: "behavior", title: "טיפול התנהגותי", desc: "גוף ונפש קשורים קשר הדוק, וטיפול התנהגותי עוזר – זה בדוק. בחיותא – אנו כאן בשבילכם. הגוף – עלינו. הנפש – עליכם." },
        { id: "existing", title: "מצב רפואי קיים", desc: "חיותא אינה מכסה סימנים קליניים שהופיעו טרם רכשת כיסוי ביטוחי. לכן אין פה תחום אפור. יש לפעול כעת. לא במבט לאחור." },
        { id: "alternative", title: "רפואה משלימה", desc: "בהארכת חיים בלא שיקולים כלכליים – חיותא תמיד מאחוריך. כל השאר – טיולים, ליטופים, דיקור סיני וצמחי מרפא - עליך." },
        { id: "castration", title: "עיקור וסירוס", desc: "חיותא תגן על חיית המחמד מכל הבלתי צפוי. עיקור וסירוס – אינם כלולים במיפוי." },
        { id: "ongoing", title: "טיפולים שוטפים", desc: "בחיותא בחרנו במתכוון שלא לכסות עלויות שוטפות. איננו מאמינים כי נכון לנו לחייב אותך על טיפולי שגרה, להם וודאי לצפות." },
    ]
}

export { PlansData };