import { LoginService } from "./LoginService";
import { Utils } from "../Utils";
import axios from "axios";

class ChatService {
    static sendAnswer(token, answer, interactionId) {
        return Utils.apiCall({
            token,
            action: 'chatAnswer',
            answer,
            curInteraction: interactionId,
        })
            .then(res => {
                return res.json()
            })
            .catch(ex => {
                throw Error("קיימת בעית תקשורת זמנית, ניתן לנסות שוב בעוד זמן קצר")
            });
    }

    static generateCCToken(token, data) {
        return Utils.apiCall({
            ...data,
            action: 'generateCCToken',
            token
        })
            .then(res => {
                return res.json()
            })
            .catch(ex => {
                throw Error("קיימת בעית תקשורת זמנית, ניתן לנסות שוב בעוד זמן קצר")
            });
    }

    static generatePayment(token, data) {
        return Utils.apiCall({
            ...data,
            action: 'generatePayment',
            token
        })
            .then(res => {
                return res.json()
            })
            .catch(ex => {
                throw Error("קיימת בעית תקשורת זמנית, ניתן לנסות שוב בעוד זמן קצר")
            });
    }


    static rewindScript(token) {
        return Utils.apiCall({
            token,
            action: 'rewindChat',
        })
            .then(res => {
                return res.json()
            })
            .catch(ex => {
                throw Error("קיימת בעית תקשורת זמנית, ניתן לנסות שוב בעוד זמן קצר")
            });
    }

    static async restartChat(token, script) {
        return Utils.apiCall({
            token,
            action: 'restartChat',
            script
        })
            .then(res => {
                return res.json()
            })
            .catch(ex => {
                throw Error("קיימת בעית תקשורת זמנית, ניתן לנסות שוב בעוד זמן קצר")
            });
    }


    /**
     * Notice! this method is called both before the answer is submitted, and again after
     * successful submission.
     * @param dispatcher
     * @param state
     * @param answer
     * @param afterSuccessfulSubmission true only if the answer was accepted by the server
     * @returns {boolean} should we continue to submission or not
     */
    static processLocalAction(dispatcher, state, answer, afterSuccessfulSubmission) {
        switch (answer.action) {
            case "restartChat":
                ChatService.processRestartChat(dispatcher, state);
                return false;
            case "closeChat":
                dispatcher({ action: "closeChat" });
                return false;
            case "analyticsEvent":
                if (afterSuccessfulSubmission)
                    dispatcher({ action: "analyticsEvent", params: answer.actionData });
                break;
            default:
                console.log("unknown local action: " + answer.action + " after server submission: " + afterSuccessfulSubmission);
        }
    }

    static uploadFile(dispatcher, state, file, answer) {
        const formData = new FormData();
        formData.append("tkn", state._token);
        formData.append("file", file, file.name);
        formData.append("ans", JSON.stringify(answer || {}));

        return axios.post("/ChatUpld.cht", formData);
    }

    static deleteUploadedFile(dispatcher, state, fileType, fileId) {
        const formData = new FormData();
        formData.append("tkn", state._token);
        formData.append("fileType", fileType);
        formData.append("fileId", fileId);

        return axios.delete("/ChatUpld.cht", {
            data: formData,
            headers: {
                'content-type': 'multipart/form-data',
            }
        });
    }


    static processAnswer(dispatcher, state, answer, type) {
        let msg = { id: answer.id, dir: "out", type: "text", text: answer.display || answer.value };
        let submittedAnswer = {...answer};

        if (type === 'html') {
            delete msg.type;
            delete msg.text;
            msg = { ...msg, type: 'html', html: answer.display };
            delete submittedAnswer.display;
            submittedAnswer.displayHtml = answer.display;
        }

        Utils.ga_event('ans_send', { id: msg.id });

        const handleCloseError = () => {
            if (document.querySelector("#userInputText"))
                document.querySelector("#userInputText").select();
        };

        if (answer.action && ChatService.processLocalAction(dispatcher, state, answer, false) === false)
            return;

        dispatcher({ action: 'setPendingAnswer', params: msg });
        dispatcher({ action: 'setTyping', params: true });


        return Promise.all([
            new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, Math.random() * 1000 + 500);
            }),
            ChatService.sendAnswer(state._token, submittedAnswer, state.curInteraction?.id)
        ])
            .then(([, data]) => {
                if (data.status !== 0) {
                    throw Error(data.errorData?.errorDescription ?? "אופס, לא הצלחנו לשלוח את התשובה במועד זה");
                } else {
                    Utils.ga_event('ans_sent', { id: msg.id });
                    dispatcher({ action: 'answerSent', params: { msg: msg, data } });
                    if (answer.action)
                        ChatService.processLocalAction(dispatcher, state, answer, true)
                    return true;
                }
            })
            .catch((ex) => {
                //chat error
                Utils.ga_event('ans_send_err', { id: msg.id });
                console.error("sendAnswer failed", ex);
                dispatcher({ action: 'setPendingAnswer', params: undefined });
                dispatcher({ action: 'setTyping', params: false });
                Utils.displaySimpleMsg(dispatcher, ex?.message ?? "לא ניתן לשלוח את התשובה במועד זה", handleCloseError);
                return false;
            });
    }

    static processRestartChat(dispatcher, state) {
        ChatService.restartChat(state._token, state._script)
            .then((data) => {
                if (data.status !== 0)
                    throw Error(data.errorData?.errorDescription ?? "אופס, לא הצלחנו לאתחל את השיחה במועד זה");
                dispatcher({ action: 'gotSession', params: data });
                return LoginService.login(data._token);
            })
            .then(data => {
                dispatcher({ action: 'loggedIn', params: data });
            })
            .catch((ex) => {
                console.error('restart chat error:', ex);
                Utils.displaySimpleMsg(dispatcher, ex?.message ?? "לא ניתן להתחיל שיחה נוספת במועד זה, אנא נסו מאוחר יותר או רעננו את הדפדפן");
            });
    }

    static processPaymentDetails(dispatcher, state, data) {
        return ChatService.generatePayment(state._token, data)
            .then((data) => {
                if (data.status !== 0)
                    throw Error(data.errorData?.errorDescription || data.errorText || "אופס, לא הצלחנו לאשר את פרטי האשראי במועד זה");
                return data;
            });
    }
}

export { ChatService }