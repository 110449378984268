import { useState } from 'react';

import { Slide, Fade } from 'react-awesome-reveal';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { Utils } from "../Utils";
import { TSPage, TSMonthInput } from '../components/commons';

import './PaymentPage.scss';

const PaymentPage = function ({ name, onBack, data, btn }) {
    const [state, dispatcher] = useApp();

    const [stateCard, setStateCard] = useState({ paymentType: "monthly" });
    const [stateErrors, setStateErrors] = useState({});
    const [stateReady, setStateReady] = useState(true);

    const handleBack = (e) => {
        if (onBack)
            onBack(true, e);
    };

    const preparePayment = data => {
        return ChatService.processPaymentDetails(dispatcher, state, data);
    };

    const handlePay = (e) => {
        e.preventDefault();
        Utils.ga_event("pay_btn_click");

        if (!validateFields()) {
            Utils.ga_event("pay_validation_err");
            return;
        }


        if (!stateReady)
            return;

        setStateReady(false);
        preparePayment(stateCard)
            .then(res => {
                console.log(res);
                //alert("got payment: " + res);
                let answer = {
                    display: "נתוני האשראי התקבלו",
                    value: res.paymentTransaction,
                    id: btn?.id,
                    field: btn?.field,
                    action: btn?.action,
                    actionData: btn?.actionData
                }
                ChatService.processAnswer(dispatcher, state, answer);
                dispatcher({ action: 'closeOverlay', params: 'payment' });
            })
            .catch(ex => {
                Utils.ga_event("pay_err", { err: ex?.message ?? "GENERAL_ERROR" });
                console.error('payment error:', ex);
                Utils.displaySimpleMsg(dispatcher, ex?.message ?? "לא ניתן לשלוח את הפרטים במועד זה, אנא נסו שוב מאוחר יותר");
            })
            .finally(() => {
                setStateReady(true);
            });
    };

    const validateFields = function () {
        var errors = {};
        if (!stateCard.card)
            errors.card = "אנחנו צריכים את מספר כרטיס האשראי שלך כדי להתקדם";

        if (stateCard.exp?.length !== 4)
            errors.exp = "יש להזין תוקף חוקי";

        if (!stateCard.exp)
            errors.exp = "יש להזין תוקף";

        if (!stateCard.cvv)
            errors.cvv = "יש להזין קוד אימות";

        if (!stateCard.ownerFirstName)
            errors.ownerFirstName = "יש להזין שם פרטי של בעל/ת הכרטיס";

        if (!stateCard.ownerLastName)
            errors.ownerLastName = "יש להזין שם משפחה של בעל/ת הכרטיס";


        if (!stateCard.ownerId)
            errors.ownerId = "יש להזין את תעודת הזהות של בעל/ת הכרטיס";



        setStateErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onFieldChange = function (id, e) {
        const cs = { ...stateCard };
        cs[id] = e.target.value;
        setStateCard(cs);
    }

    const prettyDate = str => {
        str = str || "";
        if (str.length < 8)
            return "--";

        return (str.substring(6, 8) | 0) + "/" +
            (str.substring(4, 6) | 0) + "/" +
            (str.substring(2, 4) | 0);
    };

    const cb = (_inview, ioe) => { ioe.target.querySelector(".container").classList.add("on"); }

    data = data || {};
    let paymentReq = data["policy.paymentRequest"] || {};
    let discount = data["policy.discount"] || {};
    let text1 = (paymentReq.totalDays === 365) ?
        <p>שנת הביטוח הראשונה תתחיל בתאריך {prettyDate(paymentReq.startDate)} ותסתיים בתאריך {prettyDate(paymentReq.endDate)}.</p>
        :
        <p>
            לתשומת ליבך, שנת הביטוח הראשונה תימשך {paymentReq.totalDays} ימים, מתאריך {prettyDate(paymentReq.startDate)} עד {prettyDate(paymentReq.endDate)}.
        </p>;
    let text2 = stateCard.paymentType === "monthly" ?
        <p>לכן העלות החודשית לשנה הראשונה הינה: {paymentReq.monthlyPrice / 100} ₪ לחודש.</p>
        :
        <p>לכן העלות השנתית לשנה הראשונה הינה: {(paymentReq.yearlyPrice / 100).toLocaleString()} ₪ בתשלום אחד.</p>
        ;
    let text3 = discount.desc ? <p className="discount">{discount.desc}</p> : "";

    return (
        <TSPage name={name}>
            <Slide direction="up" duration="500" onVisibilityChange={cb}>
                <div className="container on">

                    <div className="header">
                        <button className="btn right" onClick={handleBack}>
                            <img src="img/right-arrow.svg" alt="back" />
                        </button>
                        <div className="title">
                            בחירת אמצעי תשלום
                        </div>
                    </div>

                    <div className="content" style={{ marginLeft: -state?.uiParams?.scrollSize?.w + "px" }}>
                        <form onSubmit={handlePay}>
                            <div className="row payentType">
                                <label>איך נוח לכם לשלם?</label>
                                <select onChange={onFieldChange.bind(this, "paymentType")} className={stateErrors.paymentType && "error"}>
                                    <option value="monthly">תשלום חודשי</option>
                                    <option value="yearly">תשלום שנתי</option>
                                </select>
                                {
                                    stateCard.paymentType === 'monthly' ?
                                        <div className="exp">* בתשלום החודשי, כלולה תוספת דמי אשראי בגובה של עד 6% מעלות העסקה</div>
                                        :
                                        undefined
                                }
                                <div className={"validationError " + (stateErrors.paymentType && "on")}>{stateErrors.paymentType}</div>
                            </div>
                            <div className="row">
                                <label>מס' כרטיס אשראי</label>
                                <input type="text" name="cc-number" inputMode="numeric" autoComplete="cc-number" onChange={onFieldChange.bind(this, "card")} className={stateErrors.card && "error"}></input>
                                <div className={"validationError " + (stateErrors.card && "on")}>{stateErrors.card}</div>
                            </div>
                            <div className="row double">
                                <div className="part">
                                    <label>תוקף</label>
                                    <TSMonthInput type="text" onChange={onFieldChange.bind(this, "exp")} className={stateErrors.exp && "error"} />
                                    <div className={"validationError " + (stateErrors.exp && "on")}>{stateErrors.exp}</div>
                                </div>
                                <div className="part">
                                    <label>cvv</label>
                                    <input type="text" name="cc-csc"  placeholder='ספרות בגב כרטיס' inputMode="numeric" autoComplete="cc-csc" onChange={onFieldChange.bind(this, "cvv")} className={stateErrors.cvv && "error"}></input>
                                    <div className={"validationError " + (stateErrors.cvv && "on")}>{stateErrors.cvv}</div>
                                </div>
                            </div>
                            <div className="row double">
                                <div className="part">
                                    <label>שם פרטי של בעל/ת הכרטיס</label>
                                    <input type="text" name="cc-given-name" autoComplete="cc-given-name" onChange={onFieldChange.bind(this, "ownerFirstName")} className={stateErrors.ownerFirstName && "error"}></input>
                                    <div className={"validationError " + (stateErrors.ownerFirstName && "on")}>{stateErrors.ownerFirstName}</div>
                                </div>
                                <div className="part">
                                    <label>שם משפחה</label>
                                    <input type="text" name="cc-family-name" autoComplete="cc-family-name" onChange={onFieldChange.bind(this, "ownerLastName")} className={stateErrors.ownerLastName && "error"}></input>
                                    <div className={"validationError " + (stateErrors.ownerLastName && "on")}>{stateErrors.ownerLastName}</div>
                                </div>
                            </div>
                            <div className="row">
                                <label>ת.ז של בעל/ת הכרטיס</label>
                                <input type="numeric" name="cc-pid" onChange={onFieldChange.bind(this, "ownerId")} className={stateErrors.ownerId && "error"}></input>
                                <div className={"validationError " + (stateErrors.ownerId && "on")}>{stateErrors.ownerId}</div>
                            </div>

                            <div className="exp">
                                <img src="/img/icon-alert.svg" alt="" />
                                <div>
                                    {text1}
                                    {text2}
                                    {text3}
                                </div>
                            </div>
                            <button type="submit" className={"purchaseButton" + (!stateReady ? " disabled--" : "")}>רכישה</button>
                        </form>
                    </div>
                </div>
            </Slide>
            {!stateReady ?
                <Fade duration="300">
                    <div className="waiter">
                        <img src="/img/loading.svg" className="slow-rotate reverse" alt="" />
                    </div>
                </Fade>
                :
                undefined
            }
        </TSPage>
    );
}

export { PaymentPage }