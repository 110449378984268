import React, { useEffect } from 'react';

import { LoginService } from '../services/LoginService';
import { useApp } from '../app-context';

import { ChatPage } from './ChatPage';
import { LoadingPage } from './LoadingPage';
import { CantLoginPage } from './CantLoginPage';

import { ChatMsgPage } from './ChatMsgPage';
import { ChatMenuPage } from './ChatMenuPage';
import { OffersPage } from './OffersPage';
import { OffersSignupPage } from './OffersSignupPage';
import { PaymentPage } from './PaymentPage';
import { ClaimDocsPage } from './ClaimDocsPage';

import './PageManager.scss';
import { SignaturePage } from './SignaturePage';
import { TextAreaPage } from './TextAreaPage';
import { BankDetailsPage } from './BankDetailsPage';
import { BitDetailsPage } from './BitDetailsPage';


const PageManager = function () {
    const [state, dispatcher] = useApp();

    const overlays = {
        msg: { component: ChatMsgPage },
        chatMenu: { component: ChatMenuPage },
        offers: { component: OffersPage },
        offersSignup: { component: OffersSignupPage, fullscreen: true,
            onBackOverride: () => dispatcher({ action: "closeOverlay", params: "offersSignup" })},
        payment: { component: PaymentPage },
        bankAccount: { component: BankDetailsPage },
        bitAccount: { component: BitDetailsPage },
        claim_docs: { component: ClaimDocsPage },
        signer: { component: SignaturePage },
        textarea: { component: TextAreaPage }
    };

    useEffect(() => {
        let script = "";
        if (window.location.pathname) {
            script = window.location.pathname.substring(1);
            if (script.endsWith(".cht")) {
                script = script.substring(0, script.length - 4);
            }
        }

        if (window.location.search) {
            if (window.URLSearchParams) {
                let usp = new window.URLSearchParams(window.location.search);
                let tkn = usp.get("tkn");
                if (tkn) {
                    dispatcher({ action: "gotSession", params: { script, _token: tkn } });
                    if (window.history.replaceState) {
                        window.history.replaceState({}, undefined, "/" + encodeURIComponent(script) + ".cht");
                        return;
                    }
                }
            }
            if (window.history.replaceState) {
                window.history.replaceState({}, undefined, "/" + encodeURIComponent(script) + ".cht");
            }
        }

        let handleExitBot = () => {
            window.location.href = "https://chayuta.com";
        };

        let token = state._token || window.localStorage.getItem(`${script}._token`);
        if (token) {
            dispatcher({ action: "setLoggingIn", params: true });
            dispatcher({ action: "gotSession", params: { script, _token: token } });
            //return;
            let loginAction = () => {
                LoginService.login(token)
                    .then((data) => {
                        if (data.status === 0) {
                            if (data._token)
                                dispatcher({ action: 'replaceSession', params: data });
                            else
                                dispatcher({ action: "loggedIn", params: data });
                        }
                        else {
                            let ex = new Error("לא ניתן להתחבר לשרת");
                            ex.code = 500;
                            throw ex;
                        }
                    })
                    .catch((ex) => {
                        if (ex && ex.code === 500) {
                            dispatcher({ action: "loggedOff" });
                            LoginService.newSession(script)
                                .then((data) => {
                                    if (data.status === 0) {
                                        dispatcher({ action: "gotSession", params: data });
                                    } else {
                                        let ex = new Error("לא ניתן לייצר שיחה עם הבוט, כנראה הלינק שברשותך אינו תקף.");
                                        ex.code = 500;
                                        throw ex;
                                    }
                                })
                                .catch((ex) => {
                                    dispatcher({
                                        action: "openOverlay", params: {
                                            overlay: "msg", params: {
                                                className: "noBackground",
                                                children: [
                                                    <div key="txt">{ex && ex.code === 500 ? ex.message : "לא ניתן לייצר שיחה עם הבוט, כנראה הלינק שברשותך אינו תקף."}</div>,
                                                    <div key="btns" className="dialog-buttons">
                                                        <button key="btn1" onClick={handleExitBot}>לאתר חיותא</button>
                                                    </div>
                                                ]
                                            }
                                        }
                                    });
                                });
                        } else {
                            console.log("retrying login");
                            setTimeout(() => {
                                loginAction();
                            }, 5000);
                        }
                    })
            };
            loginAction();

        } else {

            dispatcher({ action: "setLoggingIn", params: true });
            let doAction = () => {
                LoginService.newSession(script)
                    .then((data) => {
                        if (data.status === 0)
                            dispatcher({ action: "gotSession", params: data });
                        else {
                            let ex = Error("לא ניתן לייצר שיחה עם הבוט, כנראה הלינק שברשותך אינו תקף.");
                            ex.code = 500;
                            throw ex;
                        }
                    })
                    .catch((ex) => {
                        if (ex && ex.code === 500) {
                            dispatcher({
                                action: "openOverlay", params: {
                                    overlay: "msg", params: {
                                        className: "noBackground",
                                        children: [
                                            <div key="txt">{ex.message || "לא ניתן לייצר שיחה עם הבוט, כנראה הלינק שברשותך אינו תקף."}</div>,
                                            <div key="btns" className="dialog-buttons">
                                                <button key="btn1" onClick={handleExitBot}>לאתר חיותא</button>
                                            </div>
                                        ]
                                    }
                                }
                            });
                        } else {
                            //dispatcher({ action: "cantLogin", params: { desc: "לא ניתן להתחבר כעת" } });
                            console.log("retrying newSession");
                            setTimeout(() => {
                                doAction();
                            }, 5000);
                        }
                    })
            };
            doAction();
        }
    }, [state._token, dispatcher]);

    let fullscreenPages = [];
    let pages = [];
    let ovIdx = 1;
    Object.entries(state._openOverlays || {}).forEach(([key, pageParams]) => {
        if (overlays[key] && overlays[key].component) {
            let props = { ...pageParams?.props || {} };
            props.key = "ov" + ovIdx++;
            props.id = key;
            props.name = key;
            props.className = "page-overlay " + (pageParams?.className || "");
            if (overlays[key].onBackOverride)
                props.onBack = overlays[key].onBackOverride;
            const arr = overlays[key].fullscreen ? fullscreenPages : pages;
            arr.push(
                React.createElement(overlays[key].component, props, pageParams?.children || [])
            );
        }
    });

    if (state.loggedIn) {
        pages.push(<ChatPage key="chatPage" />);
    } else if (state.loggingIn) {
        pages.push(<LoadingPage key="loadingPage" />);
    } else {
        pages.push(<CantLoginPage key="cantloginPage" error={state.loginError} />);
    }

    return (
        <div className="pages-container">
            <div className="fullpage">{fullscreenPages}</div>
            <div className={"page-manager" + (!state.loggingIn ? " on" : "")}>
                <div className="kramer-container">
                    <picture className={"kramer" + (state.loggedIn ? " on" : "")}>
                        <source media="(max-width: 767px)" sizes="1px"
                            srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7 1w" />
                        <img src="./img/shell/kramer.svg" alt="" />
                    </picture>
                </div>
                {pages}
            </div>
        </div>
    );
}

export { PageManager }