import { useRef, useState } from 'react';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService';

import { TSInput, TSOptionsSelector, TSDateInput, TSYearMonthSelect } from '../components/commons';

import '../components/ChatInteractionBox.scss'


const ChatInteractionBox = function () {
    const [state, dispatcher] = useApp();
    const [stateTyping, setStateTyping] = useState(false);
    const mainElement = useRef(null);

    const prepareDataList = function (msg) {
        let options = [];
        let selectOptions = msg.answers.selectOptions || {};
        if (selectOptions?.source.indexOf("data.") === 0) {
            options = (msg.data || {})[selectOptions.source.substring(5)] || [];
            options = options.map((v, k) => { return { id: v.id ?? v.v ?? v, text: v.text ?? v.d ?? v.v ?? v } })
            options.sort((a, b) => a.text === b.text ? 0 : a.text < b.text ? -1 : 1);
        }

        selectOptions.addBefore?.forEach(v => {
            options.unshift({
                id: v.id,
                text: v.text,
                first: true
            });
        });

        return options;
    }

    const actualSendMsg = (value, display) => {
        var answerObj = { field: state.lastMsg.answers.field, fieldType: state.lastMsg.answers.fieldType, id: state.lastMsg.id + "_input" };
        let answer = { ...answerObj, value };
        if (display)
            answer.display = display;

        if (state?.lastMsg?.answers?.fieldType === "selectFreeType") {
            let options = prepareDataList(state.lastMsg);
            let answerFound = false;
            options.forEach(v => {
                if (v.id === value)
                    answerFound = true;
            });
            if (!answerFound) {
                dispatcher({
                    action: "openOverlay", params: {
                        overlay: "msg", params: {
                            children: [
                                state?.lastMsg?.answers?.fieldValidationErrorTitle ?
                                    <h4 style={{marginBottom: "0.5em"}} key="h4">{state?.lastMsg?.answers?.fieldValidationErrorTitle}</h4>
                                : undefined,
                                <div key="txt">{state?.lastMsg?.answers?.fieldValidationError ?? "נא לבחור ערך מהרשימה"}</div>,
                                <div key="btns" className="dialog-buttons">
                                    <button key="btn1" onClick={e => {
                                        dispatcher({ action: "closeOverlay", params: "msg" });
                                        mainElement.current.querySelector("#userInputText").select();
                                    }}>
                                        {state?.lastMsg?.answers?.btnNameFieldError ? state?.lastMsg?.answers?.btnNameFieldError : "אישור"}
                                    </button>
                                </div>
                            ]
                        }
                    }
                });
                //alert();
                return;
            }
        }

        dispatcher({ action: "updateOptionsSelector", params: undefined });
        ChatService.processAnswer(dispatcher, state, answer)
            .then(res => {
                if (res && mainElement.current.querySelector("#userInputText"))
                    mainElement.current.querySelector("#userInputText").value = "";
            });
    };

    const handleSendMsg = function (e) {
        e.preventDefault();
        if (mainElement.current.querySelector("#userInputText"))
            actualSendMsg(mainElement.current.querySelector("#userInputText").value);
    };

    const handleSendDate = function (e) {
        e.preventDefault();
        if (mainElement.current.querySelector("#userInputText")?.value?.length >= 8) {
            let v = mainElement.current.querySelector("#userInputText").value;
            actualSendMsg(
                v.substring(4, 8) + v.substring(2, 4) + v.substring(0, 2),
                v.substring(0, 2) + "/" + v.substring(2, 4) + "/" + v.substring(4, 8)
            );
        }
    };

    const handleListItemSelected = (v) => {
        actualSendMsg(v.id, v.text);
    };

    const validateYearMonth = function() {
        let m = mainElement.current.querySelector("#userInputSelect-Month").value;
        let y = mainElement.current.querySelector("#userInputSelect-Year").value;
        return ((m !== "") && (y !== ""));
    }

    const handleSendYearMonth = function (e) {
        if (validateYearMonth()) {
            let m = mainElement.current.querySelector("#userInputSelect-Month").value | 0;
            let y = mainElement.current.querySelector("#userInputSelect-Year").value | 0;

            let yearText = y < 1 ? "" : y == 1 ? "שנה" : y + " שנים";
            let monthText = m < 1 ? "" : m == 1 ? "חודש אחד" : m + " חודשים";

            let text = yearText + (yearText && monthText ? 
                (
                    m === 1 ? 
                    " ו" :
                     " ו- "
                ) 
                : "") + monthText;
            actualSendMsg(("0"+y).slice(-2)+("0"+m).slice(-2), text);
        } else {
            dispatcher({
                action: "openOverlay", params: {
                    overlay: "msg", params: {
                        children: [
                            <div key="txt">{state?.lastMsg?.answers?.fieldValidationError ?? "נא לבחור שנה וחודש"}</div>,
                            <div key="btns" className="dialog-buttons">
                                <button key="btn1" onClick={e => {dispatcher({ action: "closeOverlay", params: "msg" });}}>OK</button>
                            </div>
                        ]
                    }
                }
            });
        }
    };

    const handleYearMonthChanged = function (e) {
        setStateTyping(validateYearMonth() ? true : false);
        return true;
    };

    const handleMsgChanged = function (e) {
        setStateTyping(e.target.value ? true : false);

        if (state?.lastMsg?.answers?.fieldType === "selectFreeType") {
            let selectOptions = state.lastMsg.answers.selectOptions || {};
            let options = prepareDataList(state.lastMsg);

            let optionsSelector = { highlight: e.target.value, options, selectOptions };
            dispatcher({ action: "updateOptionsSelector", params: e.target.value ? optionsSelector : undefined });
        }

        return true;
    };

    let inputEl = null;
    let addClasses = "";

    let sendBtnImg = <img src="img/chevron-left.svg" className="chevron" alt="arrow" />;
    let sendBtnEl = <button key="sender" id="userInputSend" className="send selection">{sendBtnImg}</button>;

    if (state?.lastMsg?.dir === "in" && state.lastMsg.answers && state.lastMsg.type !== "screen") {
        if (state.lastMsg.answers.buttons?.length > 0) {
            inputEl = <div key="input" className="helper choose">בחרו תשובה כדי להמשיך</div>;
            addClasses += " selection";
        }
        if (state.lastMsg.answers.field) {
            if (state.lastMsg.answers.fieldType === "date") {
                inputEl = <TSDateInput key="input" id="userInputText" onChange={handleMsgChanged} doOnSubmit={handleSendDate} className={"user-input " + (state.lastMsg.answers.fieldType || "text")} placeholder="dd/mm/yyyy" />;
                sendBtnEl = <button key="sender" id="userInputSend" className={"send" + (!stateTyping ? " note" : " ready")} href="#" onClick={handleSendDate}>{sendBtnImg}</button>;
            } else if (state.lastMsg.answers.fieldType === "MonthYear") {
                inputEl =   <TSYearMonthSelect key="input" id="userInputSelect" onChange={handleYearMonthChanged} className={"user-selection " + (state.lastMsg.answers.fieldType || "text")} numYears="18" />
                sendBtnEl = <button key="sender" id="userInputSend" className={"send" + (!stateTyping ? " note" : " ready")} href="#" onClick={handleSendYearMonth}>{sendBtnImg}</button>;
            } else {
                inputEl = <TSInput key="input" id="userInputText" type={state.lastMsg.answers.fieldType === "email" ? "email" : undefined} onChange={handleMsgChanged} doOnSubmit={handleSendMsg} className={"user-input " + (state.lastMsg.answers.fieldType || "text")} placeholder="הקלדת תשובה כאן..." autoComplete={state.optionsSelector ? "off" : undefined} />;
                sendBtnEl = <button key="sender" id="userInputSend" className={"send" + (!stateTyping ? " note" : " ready")} href="#" onClick={handleSendMsg}>{sendBtnImg}</button>;
            }
        }
    } else if (state.chat_error) {
        addClasses += " selection";
        inputEl = <div key="input" className="helper">אופס, שימו לב מה קרה</div>;
    }

    return (
        <div ref={mainElement}>
            {state.optionsSelector ? <TSOptionsSelector highlight={state.optionsSelector.highlight} options={state.optionsSelector.options} selectOptions={state.optionsSelector.selectOptions} onSelected={handleListItemSelected} /> : undefined}
            <div className={"chat-interaction-box" + addClasses + (state.awaitingAnswer ? " answer" : "")}>
                {[inputEl, sendBtnEl]}
            </div>
        </div>
    );
}

export { ChatInteractionBox }
