import { useState, useRef } from 'react';

import { Slide } from 'react-awesome-reveal';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { TSPage } from '../components/commons';
import SignatureCanvas from 'react-signature-canvas'

import './SignaturePage.scss';

const SignaturePage = function ({ name, onBack, data, btn }) {
    const [state, dispatcher] = useApp();

    const signatureCanvas = useRef();

    const [stateBusy, setStateBusy] = useState(false);

    const handleClear = (e) => {
        if (stateBusy) return;

        signatureCanvas.current?.clear();
    };

    const handleSubmit = (e) => {
        if (stateBusy || !signatureCanvas.current || signatureCanvas.current.isEmpty()) return;

        let answer = {
            display: "חתימה",
            value: signatureCanvas.current.toDataURL(),
            id: btn?.id,
            field: btn?.field
        }

        setStateBusy(true);
        ChatService.processAnswer(dispatcher, state, answer)
            .then(() => {
                dispatcher({ action: 'closeOverlay', params: 'signer' });
            })
            .catch(ex => {
                alert(ex);
            }).finally(() => {
                setStateBusy(false);
            });

    };

    const handleBack = (e) => {
        if (stateBusy) return;

        if (onBack)
            onBack(true, e);
    };

    const cb = (_inview, ioe) => { ioe.target.querySelector(".container").classList.add("on"); }

    let terms = (btn?.data?.terms || []).map((v, k) => <p key={`term${k}`}>{v}</p>);

    data = data || {};

    let canvasWidth = Math.min(state.uiParams.windowSize.w, 768) - (state.uiParams.emSize.w * 0.7) * 2;
    return (
        <TSPage name={name}>
            <Slide direction="up" duration="500" onVisibilityChange={cb}>
                <div className="container on">

                    <div className="header">
                        <button className="btn right" onClick={handleBack}>
                            <img src="img/right-arrow.svg" alt="back" />
                        </button>
                        <div className="title">
                            חתימה
                        </div>
                    </div>

                    <div className="content" style={{ marginLeft: -state?.uiParams?.scrollSize?.w + "px" }}>
                        <div className="terms">
                            <h3>קראו בעיון את ההסכם וחיתמו עליו</h3>
                            {terms}
                        </div>

                        <div className="signature-box">
                            <button className="clear-btn" onClick={handleClear}><img src="/img/icon-trash.svg" alt="clear" /></button>
                            <h3>הוסף את חתימתך בעזרת האצבע או העכבר</h3>
                            <div className="sig-pad">
                                <SignatureCanvas ref={signatureCanvas} penColor='black' canvasProps={{ width: canvasWidth, height: 120, className: 'sigCanvas' }} />
                            </div>
                            <div className="dialog-buttons">
                                <button onClick={handleSubmit}>אישור</button>
                                <button onClick={handleBack}>ביטול</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Slide>
        </TSPage>
    );
}

export { SignaturePage }