import React from 'react'
//import { animateScroll } from 'react-scroll';
import { Utils } from './Utils';

const demo_messages = [];

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

function processAnswers(msg) {
    var answers = msg.answers;
    if (answers && answers.action) {
        switch (answers.action) {
            case "requestContact":
                //this.notify("requestContact");
                break;
            case "requestOptOut":
                //this.notify("requestOptOut");
                break;
            default:
                //this.notify("generalAction", {action: answers.action});
                break;
        }
    }
}

function processInteraction(messages, msg, allowDelay) {
    var msgs = msg.msgs;
    var delay = 0;
    if (!msgs) msgs = [msg];

    for (var i = 0; i < msgs.length; i++) {
        var cmsg = msgs[i];
        cmsg.id = msg.id;
        cmsg.dir = msg.dir;
        cmsg.data = msg.data;
        cmsg.avatarImg = msg.avatarImg;
        cmsg.backLog = msg.backLog;
        if (allowDelay) {
            var mdelay = cmsg.delay;
            cmsg.delay = delay;
            if (mdelay * 1 > 0)
                delay += mdelay * 1;
            if (delay > 0 && i < msgs.length - 1)
                cmsg.next_delay = delay;
        } else
            cmsg.delay = 0;

        if (msg.dir === "in" && msg.answers && i === msgs.length - 1) {
            // this.awaitingAnswer = true;
            cmsg.answers = msg.answers;
            processAnswers(cmsg);
        }

        messages.push(cmsg);
    }
}

function actionHandler(state, { action, params }) {
    switch (action) {
        case 'setLoggingIn':
            return { ...state, loggingIn: true };
        case 'cantLogin':
            return { ...state, loggingIn: false };
        case 'gotSession':
            window.localStorage.setItem(`${params.script}._token`, params._token);
            return { ...state, typing: false, pendingAnswer: undefined, messages: [], _token: params._token };
        case 'replaceSession': {
            window.localStorage.setItem(`${params.script}._token`, params._token);
            state = { ...state, typing: false, pendingAnswer: undefined, messages: [], _token: params._token };
            //NOTE: careful this intentionally goes into loggedIn handler
        }
        case 'loggedIn': {
            let messages = [];
            params.backlog.forEach((v) => {
                v.backLog = true;
                if (v.dir === "out") {
                    v.type = "text";
                    if (v.displayHtml) {
                        v.html = v.displayHtml;
                        v.type = "html";
                    } else {
                        v.text = v.display || v.value || "";
                    }
                } else {
                    v.dir = "in";
                }
                processInteraction(messages, v);
            });
            if (params.interaction) {
                params.interaction.dir = "in";
                processInteraction(messages, params.interaction)
            }
            return { ...state, loggingIn: false, loggedIn: true, curInteraction: params.interaction, messages, lastMsg: messages[messages.length - 1] }
        }
        case 'loggedOff': {
            try {
                window.localStorage.removeItem(`${params.script}._token`);
            } catch (ignore) { }
            return { ...state }
        }

        case 'answerSent': {
            let messages = [...state.messages];
            processInteraction(messages, params.msg);

            if (params.data.nextInteraction) {
                params.data.nextInteraction.dir = "in";
                Utils.ga_event('q_rec', { id: params?.data?.nextInteraction?.id });

                //we do actionBefore evaluation only as a result of answerSent
                if (params.data.nextInteraction.actionBefore === 'analyticsEvent') {
                    try {
                        if (window.top !== window) {
                            window.top.postMessage('analyticsEvent|' + JSON.stringify({
                                ...params.data.nextInteraction.actionBeforeData,
                                _seq: new Date().getTime()
                            }), '*');
                        }
                    } catch(ex) {
                        console.error(ex);
                    }
                }

                processInteraction(messages, params.data.nextInteraction, true);
                return { ...state, messages, lastMsg: messages[messages.length - 1], curInteraction: params.data.nextInteraction, typing: false, pendingAnswer: undefined };
            }

            return { ...state, messages: messages, lastMsg: messages[messages.length - 1] };
        }
        case 'setPendingAnswer': {
            return { ...state, pendingAnswer: params };
        }
        case 'setTyping': {
            return { ...state, typing: params };
        }


        case 'openOverlay': {
            let oo = { ...state._openOverlays };
            oo[params.overlay] = params.params;
            return { ...state, _openOverlays: oo };
        }
        case 'closeOverlay': {
            let oo = { ...state._openOverlays };
            delete oo[params];
            return { ...state, _openOverlays: oo };
        }

        case 'updateUiParams':
            return { ...state, uiParams: params };
        case 'updateOptionsSelector':
            return { ...state, optionsSelector: params };

        case 'closeChat':

            if (window.top !== window) {
                window.top.postMessage('closeSalesBot', '*');
            } else {
                window.location.href = "https://chayuta.com"
            }

            return { ...state };
        case 'analyticsEvent':
            if (window.top !== window) {
                window.top.postMessage('analyticsEvent|' + JSON.stringify({...params, _seq: new Date().getTime()}), '*');
            }
            return { ...state };
        default: {
            throw new Error(`Unhandled action type: ${action}`)
        }
    }
}

function AppProvider({ uiParams, children }) {
    const [state, dispatch] = React.useReducer(actionHandler, { messages: demo_messages, uiParams: uiParams, firstLogin: true })

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    )
}

function useAppState() {
    const context = React.useContext(AppStateContext);
    if (context === undefined)
        throw new Error('useAppState must be used within AppProvider');
    return context;
}

function useAppDispatch() {
    const context = React.useContext(AppDispatchContext);
    if (context === undefined)
        throw new Error('useAppDispatch must be used within AppProvider');
    return context;
}

function useApp() {
    return [useAppState(), useAppDispatch()]
}

export { AppProvider, useAppState, useAppDispatch, useApp }