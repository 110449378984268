import React, { useEffect, useState } from "react";

import { Zoom, Bounce } from "react-awesome-reveal";
import SVG from 'react-inlinesvg';

import { ChatService } from '../services/ChatService';
import { useApp } from '../app-context'
import { Utils } from "../Utils";


function ChatTyping() {
    return (
        <SVG src="img/typing.svg" />
    );
}

function ChatMsgBubble({ text, html, classes, children, popDuration, delay }) {
    const [stateDisplay, setStateDisplay] = useState(false);
    useEffect(() => {
        if (delay * 1 > 0)
            setTimeout(setStateDisplay.bind(this, true), delay * 1)
        else
            setStateDisplay(true);
    }, []);

    if (!stateDisplay)
        return null;

    if (html)
        return (
            <Zoom cascade duration={popDuration || 0} triggerOnce="true">
                <div>
                    <div className={"chat-msg-bubble on" + (classes ? " " + classes : "")}>
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                        {children}
                    </div>
                </div>
            </Zoom>
        );
    else
        return (
            <Zoom cascade duration={popDuration || 0} triggerOnce="true">
                <div>
                    <div className={"chat-msg-bubble on" + (classes ? " " + classes : "")}>
                        {text} {children}
                    </div>
                </div>
            </Zoom>
        );
}

function ChatImageBubble({ type, img, fallback }) {
    return (
        <div className={"chat-img-bubble on" + (type ? " " + type : "")}>
            <TSFallbackImage src={img} fallback={fallback} /*onLoad: this.props.onLoad}*/ />
        </div>
    );
}

function TSFallbackImage({ onLoad, fallback, src }) {
    const [state, setState] = useState({ failedLoading: false });

    function handleError(e) {
        setState({ failedLoading: true });
    }

    return (
        <img onError={handleError} onLoad={onLoad} src={state?.failedLoading ? fallback : src} alt="fallback" />
    );
}

function ChatAvatar({ img }) {
    return (
        <div className="chat-msg-avatar-container">
            <TSFallbackImage src={img || "img/avatar.svg"} fallback="img/avatar.svg" alt="avatar" />
        </div>
    );
}

function ChatEditBtn({ onClick }) {
    return (
        <button className="chat-msg-edit" onClick={onClick}>
            <Zoom triggerOnce="true" duration="350">
                <img src="img/edit.svg" alt="edit" />
            </Zoom>
        </button>
    );
}

function TSClickButton({ onClick, component, children, className, notNormal }) {
    const handleClick = function (e) {
        onClick?.(e)
    };

    return React.createElement(component || "a", {
        onClick: handleClick,
        className: (!notNormal ? "normalButton" : "") + (className ? " " + className : "")
    }, children);
}

function ChatMessage({ msg, avatar, onEdit, scroller }) {
    const [state, dispatcher] = useApp();

    const [stateDisplay, setStateDisplay] = useState(false);
    const [stateTempAvatar, setStateTempAvatar] = useState(false);

    useEffect(() => {
        if (msg && msg.delay * 1 > 0)
            setTimeout(setStateDisplay.bind(this, true), msg.delay * 1)
        else
            setStateDisplay(true);

        if (msg && msg.next_delay * 1 > 0) {
            setStateTempAvatar(true);
            setTimeout(setStateTempAvatar.bind(this, false), msg.next_delay * 1)
        }

    }, [msg]);

    useEffect(() => {
        if (scroller)
            scroller();
    });

    if (!stateDisplay)
        return null;

    const handleScreenBack = function (screen, e) {
        let actualBack = (e) => {
            dispatcher({ action: "closeOverlay", params: "msg" });
            dispatcher({ action: "closeOverlay", params: screen });
        };
        let cancelBack = (e) => {
            dispatcher({ action: "closeOverlay", params: "msg" });
        };

        dispatcher({
            action: "openOverlay", params: {
                overlay: "msg", params: {
                    children: [
                        <div key="txt">בטוח שברצונך לצאת ממסך זה מבלי לשמור?</div>,
                        <div key="btns" className="dialog-buttons">
                            <button key="btn1" onClick={actualBack}>אישור</button>
                            <button key="btn2" onClick={cancelBack}>ביטול</button>
                        </div>
                    ]
                }
            }
        });
    }

    const handleBtnClicked = function (btn) {
        if (btn.screen) {
            Utils.ga_event("ans_btn_scr_open", { id: btn.screen });
            let data = state?.lastMsg?.data ?? {};
            if (!(state?._openOverlays || {})[btn.screen]) {
                let pd = { data, btn };
                pd.onBack = handleScreenBack.bind(this, btn.screen);
                dispatcher({ action: 'openOverlay', params: { overlay: btn.screen, params: { hasHeader: true, props: pd } } });
            }
        } else {
            Utils.ga_event("ans_btn_click", { id: btn?.id });
            ChatService.processAnswer(dispatcher, state, btn);
        }
    };

    const msgToButtons = function (msg) {
        var content = [];
        for (var i = 0; i < msg.buttons.length; i++) {
            var btn = msg.buttons[i];
            content.push(<TSClickButton key={"b" + i} className="chat-answer-btn" onClick={handleBtnClicked.bind(this, btn)}>{btn.display || btn.value || "ללא טקסט"}</TSClickButton>);
        }

        return (
            <div className={"chat-answer " + msg.type}>
                {content}
            </div>
        );
    };

    const msgToImgButtons = function (msg) {
        var content = [];

        var btns = [];

        for (var i = 0; i < msg.buttons.length; i++) {
            var btn = msg.buttons[i];
            btns.push(
                <Bounce key={"b" + i} duration={750} delay={Math.random() * 750} triggerOnce="true" className="chat-answer-img-btn">
                    <button onClick={handleBtnClicked.bind(this, btn)} style={{ userSelect: "none" }} >
                        {
                            [
                                <img key="img" src={"img/ans/" + btn.img} alt={btn.display || btn.value} style={{ userSelect: "none" }} />,
                                <span key="text">{btn.display || btn.value}</span>
                            ]}
                    </button>
                </Bounce>
            );

            if (btn?.options?.lineBreak) {
                content.push(
                    <div className="imgButtonsRow">
                        {btns}
                    </div>
                )
                btns = [];
            }
        }

        if (btns.length) {
            content.push(
                <div className="imgButtonsRow">
                    {btns}
                </div>
            );
        }

        return (
            <div className={"chat-answer " + msg.type}>
                {content}
            </div>
        );
    };

    if (!msg) return null;

    if (msg.type === "buttons" && msg.buttons)
        return msgToButtons(msg);

    if (msg.type === "imageButtons" && msg.buttons)
        return msgToImgButtons(msg);

    let content = [];
    let popDuration = msg.backLog ? 0 : 350;
    switch (msg.type) {
        case "text":
        case "screen":
            content.push(
                <ChatMsgBubble key="txt" text={msg.text || ""} html={msg.html || ""} classes={msg.classes} style={{ userSelect: "none" }} popDuration={popDuration}>
                    {onEdit ? <ChatEditBtn onClick={onEdit} /> : undefined}
                </ChatMsgBubble>
            );
            break;
        case "html":
            content.push(
                <ChatMsgBubble key="html" html={msg.html} classes={msg.classes} popDuration={popDuration}>
                    {onEdit ? <ChatEditBtn onClick={onEdit} /> : undefined}
                </ChatMsgBubble>
            );
            break;
        case "image": content.push(<ChatImageBubble key="img" img={msg.img} fallback={msg.fallback} text={msg.text} type={msg.imgType} popDuration={popDuration} />); break;
        /*case "youtube": content.push = React.DOM.div({ className: "youtubeWrapper" }, React.DOM.iframe({
            width: "100%", height: "100%", src: "https://www.youtube.com/embed/" + msg.youtubeId + "?enablejsapi=1", frameBorder: 0, onLoad: function (e) {
                setTimeout(function () { CCController.scrollToBottom(); }, 100);
            }
        })); break;
        */
        case "typing":
            content.push(
                <div key="typing" className="chat-typing-container">
                    <ChatTyping key="typing" />
                </div>
            );
            break;
        default: content = "screen";
    }

    if (stateTempAvatar || avatar !== undefined)
        content.push(<ChatAvatar key="avatar" img={avatar} />);

    return (
        <div className={"chat-message " + (msg.dir ? "dir" + msg.dir : "")}>
            {content}
        </div>
    );
}

export { ChatMessage }