import { Slide } from 'react-awesome-reveal';

import { useApp } from '../app-context';

import { ChatService } from '../services/ChatService';
import { Utils } from '../Utils';
import { TSPage } from '../components/commons';

const ChatMenuPage = function ({ name, className }) {
    const [state, dispatcher] = useApp();

    const handleRestartChat = function (e) {
        e.preventDefault();
        ChatService.processRestartChat(dispatcher, state);
        dispatcher({ action: 'closeOverlay', params: 'chatMenu' });
        e.stopPropagation();
    };
    const handleCloseMenu = function (e) {
        e.preventDefault();
        dispatcher({ action: 'closeOverlay', params: 'chatMenu' });
    };
    const handleGoHome = function (e) {
        e.preventDefault();
        Utils.displaySimpleYNQuestion(dispatcher, {
            content: "בטוח שברצונך לסגור ולעבור לאתר חיותא?",
            onYes: () => {
                dispatcher({ action: 'closeChat', params: {} });
            }
        });
    };

    return (
        <TSPage name={name} className={className} onClick={handleCloseMenu}>
            <Slide direction="right" duration="350" style={{ height: "100%" }}>
                <div className="menu">
                    <button key="close" onClick={handleCloseMenu}>
                        <img src="img/right-arrow.svg" alt="close" />
                    </button>
                    <button key="restart" onClick={handleRestartChat}>
                        <img src="img/icon-restart.svg" alt="restart chat" />
                        התחלת שיחה מחדש
                    </button>
                    <button key="home" onClick={handleGoHome}>
                        <img src="img/icon-home.svg" alt="home" />
                        לדף הבית
                    </button>
                </div>
            </Slide>
        </TSPage>
    );
}

export { ChatMenuPage }