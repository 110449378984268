import { Utils } from "../Utils";

class LoginService {
    static newSession(script) {
        return Utils.apiCall({
            action: 'newSession',
            script
        })
            .then(res => {
                return res.json()
            })
            .catch(ex => {
                throw Error("קיימת בעית תקשורת זמנית, ניתן לנסות שוב בעוד זמן קצר")
            });
    }

    static login(token) {
        return Utils.apiCall({
            action: 'chatLogin',
            token
        })
            .then(res => {
                return res.json()
            })
            .catch(ex => {
                throw Error("קיימת בעית תקשורת זמנית, ניתן לנסות שוב בעוד זמן קצר")
            });
    }
}

export { LoginService }