import {Fade} from 'react-awesome-reveal';

import "./LoadingPage.scss";


const LoadingPage = function() {
    return (
        <Fade>
            <div className="loading-page">טוען.. מייד מתחילים</div>
        </Fade>
    );
}

export {LoadingPage}