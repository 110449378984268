import { useEffect, useState } from 'react';

import { Slide } from 'react-awesome-reveal';
import { Link, Element, scroller } from 'react-scroll'

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { TSToggle, TSLineSelector, TSPage, TSAccordion } from '../components/commons';

import { PlansData } from '../plansData';

import './OffersPage.scss';
import { Utils } from '../Utils';

const ImagesListItem = function ({ id, img, blob, title, desc }) {
    return (
        <div className="item">
            <div className="image">
                <div className="positioner">
                    <img src={`img/offers/blob-${blob}.svg`} alt="blob" />
                    <img src={img} alt={id} />
                </div>
            </div>
            <div>
                <div className="title">{title}</div>
                <div className="desc">{desc}</div>
            </div>
        </div>
    );
};

const BannerDiscount = function ({ discount }) {
    const [stateActive, setStateActive] = useState(false);
    useEffect(() => {
        setStateActive(true);
    }, []);

    if (!discount?.desc)
        return "";

    return (<div className={"discount" + (stateActive ? " on" : "")}>
        <div className="innerDiscount">
            {discount.desc}
        </div>
    </div>)
};

const OffersPage = function (props) {
    const [state, dispatcher] = useApp();

    useEffect(()=>{
        dispatcher({ action: "analyticsEvent", params: {"category": "signup", "event": "offer_opened"} });
    }, []);

    const [statePeriodYear, setStatePeriodYear] = useState(false);
    const [stateSelectedPlan, setSelectedPlan] = useState((((props?.data || {})["policy.plans"] || []).filter(v => !v.soon).reverse()[0] || {}).id);
    const [stateExcess, setExcess] = useState('250');
    const [stateOpenedAcc, setStateOpenedAcc] = useState([false, false, false]);
    const [stateOpenedPlans, setStateOpenedPlans] = useState(false);

    const handleBack = (e) => {
        if (props.onBack)
            props.onBack(true, e);
    };

    const hanldePeriodChange = (onoff) => {
        Utils.ga_event(!!onoff ? "offer_year_price" : "offer_month_price", {});
        setStatePeriodYear(!!onoff);
    };

    const handleSelectPlan = (planId) => {
        Utils.ga_event("offer_plan_sel", { planId });
        setSelectedPlan(planId);
    };

    const handleExcessSelected = (idx, value) => {
        Utils.ga_event("offer_excess_chg", { excess: value });
        setExcess(value);
    };

    const handlePurchase = (e) => {
        Utils.ga_event("offer_purchase_click", {});
        if (stateSelectedPlan) {
            let plan = ((props?.data || {})["policy.plans"] || []).filter(p => p.id === stateSelectedPlan);
            if (plan.length < 1) {
                Utils.ga_event("offer_purchase_err", { err: "NO_PLAN" });
                return;
            }
            let desc = "פוליסת " +
                plan[0].title +
                " ב- ₪" + (plan[0].excess[stateExcess].price / 100).toLocaleString() +
                " לשנה או ₪" + plan[0].excess[stateExcess].monthlyPrice / 100 +
                " לחודש";
            let discountDesc = props.data?.["policy.discount"]?.desc;
            if (discountDesc)
                desc += ".<br/>" + discountDesc;

            let answer = {
                display: desc,
                value: plan[0].id + ":" + stateExcess,
                id: props?.btn?.id,
                field: props?.btn?.field,
                action: props?.btn?.action,
                actionData: props?.btn?.actionData
            }
            ChatService.processAnswer(dispatcher, state, answer, "html");
            dispatcher({ action: 'closeOverlay', params: 'offers' });
        }
    };

    const toggleAcc = (idx, toggle) => {
        Utils.ga_event("offer_acc_tgl", { toggle });
        var a = [...stateOpenedAcc];
        a[idx] = toggle;
        setStateOpenedAcc(a);
    };

    const togglePlans = (toggle) => {
        Utils.ga_event("offer_plans_tgl", { toggle });
        setStateOpenedPlans(toggle);
    }

    const scrollToZEl = (el) => {
        setTimeout(() => {
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    scroller.scrollTo(el, {
                        duration: 500,
                        delay: 0,
                        smooth: true,
                        containerId: 'offersContainer',
                        offset: 10
                    });
                });
            });
        }, 300);
    };

    const handleAccToggle = (idx, toggle) => {
        toggleAcc(idx, toggle);
    };

    const handlePlansToggled = (toggle) => {
        togglePlans(toggle);
    }

    const handlePlansClicked = (e) => {
        togglePlans(true);
        scrollToZEl('plans');
    };

    const handleWhatCoveredClicked = (e) => {
        toggleAcc(0, true);
        scrollToZEl('acc1');
    };

    const handleWhatNotCoveredClicked = (e) => {
        toggleAcc(1, true);
        scrollToZEl('acc2');
    };

    const handleFaqClicked = (e) => {
        toggleAcc(2, true);
        scrollToZEl('acc3');
    };

    let plans = [];
    let excessOptions = [];
    let excessSet = new Set();
    let selectedPlan = undefined;

    ((props?.data || {})["policy.plans"] || []).forEach(v => {
        let price = v?.excess?.[stateExcess]?.price;
        let monthlyPrice = v?.excess?.[stateExcess]?.monthlyPrice;

        for (const copayment of v.copaymentList) {
            if (excessSet.has(copayment.v))
                break;
            excessOptions.push(copayment);
            excessSet.add(copayment.v);
        }

        plans.push(
            <div key={`p${v.id}`} className={"plan" + (v.id === stateSelectedPlan ? " selected " : "")}>
                <div className="title">{v.title || ""}</div>
                <div className="desc">
                    {statePeriodYear ? <div className="pricingPerYear">{Math.ceil(price / 100).toLocaleString()} ₪ לשנה</div> : <div className="pricingPerMonth">{Math.ceil(monthlyPrice / 100)} ₪ לחודש</div>}
                    <div className="coverage">כיסוי עד {v.coverage} ₪ לשנה</div>
                </div>
                <button onClick={v.soon ? undefined : handleSelectPlan.bind(this, v.id)} className={"selector" + (v.disabled ? " disabled" : "") + (v.soon ? " soon" : "")}>{v.soon ? "בקרוב" : v.id === stateSelectedPlan ? "נבחר" : "בחר"}</button>
            </div>
        );
        if (v.id === stateSelectedPlan)
            selectedPlan = v;
    });

    let accData = { covered: [], notCovered: [] };
    ["covered", "notCovered"].forEach(section => {
        (PlansData[section] || []).forEach(v => {
            if (v.conditions?.length > 0) {
                let types = selectedPlan?.type ?? [];
                let found = false;
                v.conditions.forEach(c => {
                    if (types.indexOf(c) >= 0) {
                        found = true;
                        return false;
                    }
                });
                if (!found)
                    return true;
            }
            accData[section].push(
                <ImagesListItem key={section + "." + v.id} blob={section === "covered" ? "blue" : "red"} img={`img/offers/${v.id}.svg`} title={v.title} desc={v.desc} />
            )
        })
    });

    const cb = (_inview, ioe) => { ioe.target.querySelector(".container").classList.add("on"); }
    let price = selectedPlan && selectedPlan.excess && selectedPlan.excess[stateExcess] && selectedPlan.excess[stateExcess].monthlyPrice ? "₪" + (selectedPlan.excess[stateExcess].monthlyPrice / 100) : "";
    let title = <h3>כיסוי <b>{selectedPlan && selectedPlan.title ? selectedPlan.title : ""}</b> עבור {(props?.data || {})["pet.name"]}</h3>;
    let hasIllness = (selectedPlan?.type || []).join(" ").indexOf("illness") >= 0;
    let deductiblePercent = selectedPlan?.deductiblePercent ?? 10;

    return (
        <TSPage name={props.name}>
            <Slide direction="up" duration="500" onVisibilityChange={cb} >
                <div className="container">
                    <div className="header">
                        <button className="btn right" onClick={handleBack}>
                            <img src="img/right-arrow.svg" alt="back" />
                        </button>
                        <div className="title">
                            הצעה אישית
                        </div>
                    </div>
                    <div className="tabs">
                        <Link activeClass="active" className="tab" to="offer" containerId="offersContainer" spy={true} smooth={true} isDynamic={true} duration={500}>
                            הצעה
                            <div className="marker" />
                        </Link>
                        <Link activeClass="active" className="tab" to="plans" containerId="offersContainer" spy={true} smooth={true} isDynamic={true} duration={500} onClick={handlePlansClicked}>
                            מסלולים
                            <div className="marker" />
                        </Link>
                        <Link activeClass="active" className="tab" to="acc1" containerId="offersContainer" spy={true} smooth={true} duration={500} delay={300} isDynamic={true} onClick={handleWhatCoveredClicked}>
                            מה מכוסה?
                            <div className="marker" />
                        </Link>
                        <Link activeClass="active" className="tab" to="acc2" containerId="offersContainer" spy={true} smooth={true} duration={500} delay={300} isDynamic={true} onClick={handleWhatNotCoveredClicked}>
                            מה לא?
                            <div className="marker" />
                        </Link>
                        <Link activeClass="active" className="tab" to="acc3" containerId="offersContainer" spy={true} smooth={true} duration={500} delay={300} isDynamic={true} onClick={handleFaqClicked}>
                            שאלות נפוצות
                            <div className="marker" />
                        </Link>
                    </div>
                    <div className="content" style={{ marginLeft: -state?.uiParams?.scrollSize?.w + "px" }} id="offersContainer">
                        <Element name="offer" className="selectedOffer">
                            <div className="inner">
                                <div className={"banner " + (props.data?.["policy.discount"]?.desc ? " hasDiscount" : "")}>
                                    <BannerDiscount discount={props.data?.["policy.discount"]} />
                                    <img className="bg" src="img/offers/banner.svg" alt="banner" />
                                    {title}
                                    <div className="price">
                                        <div>{price}</div>
                                        <div className="desc">לחודש</div>
                                    </div>
                                    <img className="dog" src="img/offers/dog.svg" alt="dog" />
                                </div>
                                <div className="perks">
                                    <div className="perk">
                                        <img src="img/offers/perk1.svg" alt="" />
                                        <div className="text">מטפלים היכן שרוצים ומתי שרוצים</div>
                                    </div>
                                    <div className="perk">
                                        <img src="img/offers/perk2.svg" alt="" />
                                        <div className="text">לא מתלבטים בין בדיקות.<br />
                                            לא חושבים על עלויות</div>
                                    </div>
                                    <div className="perk">
                                        <img src="img/offers/perk3.svg" alt="" />
                                        <div className="text">{"מכסים את כל " + (hasIllness ? "המחלות והפציעות" : "הפציעות והתאונות") + " הבלתי צפויות"}</div>
                                    </div>
                                    <div className="perk">
                                        <img src="img/offers/perk4.svg" alt="" />
                                        <div className="text">אין שינוי עלויות במקרה של תביעות</div>
                                    </div>
                                </div>
                            </div>
                        </Element>

                        <Element name="plans" className="planSelection">
                            <div className="inner">
                                <TSAccordion className="plans-acc" title="מסלולי ביטוח" forceToggle={stateOpenedPlans} onToggle={handlePlansToggled.bind(this)}>

                                    <div className="periodSelector">
                                        <div className="wrap">
                                            <span className={statePeriodYear ? "" : "selected"}>מחיר חודשי</span>
                                            <TSToggle onChange={hanldePeriodChange} value={statePeriodYear}></TSToggle>
                                            <span className={statePeriodYear ? "selected" : ""}>מחיר שנתי</span>
                                        </div>
                                    </div>

                                    <div className="plans">
                                        {plans}

                                        <div className="excess">
                                            <div className="title">השתתפות עצמית</div>
                                            <div>{deductiblePercent}% מגובה התביעה ולא פחות מ-</div>
                                            <TSLineSelector options={excessOptions} onChange={handleExcessSelected} value={stateExcess}></TSLineSelector>
                                            <div className="exp">
                                                השתתפות עצמית מחושבת מסך כל העלויות שהוצאת על אירוע רפואי,
                                                גם אם האירוע נמשך לאורך תקופה וכלל מספר ביקורים.
                                                <p style={{ fontSize: "0.8em", marginTop: ".3em", marginBottom: "1em" }}>*בכפוף לתנאי הפוליסה</p>
                                            </div>
                                        </div>
                                    </div>

                                </TSAccordion>
                            </div>
                        </Element>

                        <div className="accordion">
                            <Element name="acc1" className="element">
                                <TSAccordion className="what-covered" title="מה מכוסה?" forceToggle={stateOpenedAcc[0]} onToggle={handleAccToggle.bind(this, 0)}>
                                    {accData.covered}
                                </TSAccordion>
                            </Element>
                            <Element name="acc2" className="element">
                                <TSAccordion className="what-not-covered" title="מה לא מכוסה?" forceToggle={stateOpenedAcc[1]} onToggle={handleAccToggle.bind(this, 1)}>
                                    {accData.notCovered}
                                </TSAccordion>
                            </Element>
                            <Element name="acc3" className="element">
                                <TSAccordion className="faq" title="שאלות נפוצות" forceToggle={stateOpenedAcc[2]} onToggle={handleAccToggle.bind(this, 2)}>
                                    <TSAccordion title="איך עובדת ההשתתפות העצמית?" forceToggle={true}>
                                        <div>
                                            <p>
                                                השתתפות עצמית היא סכום המקוזז מההחזר בעבור תביעה. ההשתתפות מחושבת מסך כל הטיפולים, הוטרינרים, המומחים ומרכזי החירום שחיית המחמד הייתה צריכה לקבל אצלם טיפול, תחת אותו אירוע רפואי ספציפי.
                                            </p>
                                            <p>
                                                מתוך סך העלויות באירוע הרפואי מחושב סכום ההחזר וממנו מקוזזת ההשתתפות העצמית. בעת ההצטרפות לביטוח ניתן לבחור בין השתתפות עצמית נמוכה יותר ועלות שנתית גבוהה יותר, לבין השתתפות עצמית גבוהה יותר ועלות שנתית נמוכה יותר.
                                            </p>
                                        </div>
                                    </TSAccordion>

                                    <TSAccordion title="כשאסיים את תהליך ההרשמה הביטוח ייכנס לתוקף?">
                                        <div>
                                            <p>
                                                הביטוח יכנס לתוקף בתאריך המופיע במסך הזנת אמצעי התשלום.
                                            </p>
                                            <p>
                                                במידת הצורך אנו ניצור איתך קשר, נעבור על הפרטים בקצרה ונעביר את הביטוח להפקה.
                                            </p>
                                            <p>
                                                כשהפוליסה תיכנס לתוקף היא תשלח אליך במייל עם כל המידע החשוב.
                                            </p>
                                        </div>
                                    </TSAccordion>
                                    <TSAccordion title="מדוע גיל הכלב משפיע על מחיר הפוליסה?">
                                        <div>
                                            <p>
                                                מחיר הפוליסה נקבע לפי חישובים סטטיסטיים שנצברו ונאספו לאורך שנים במרפאות ווטרינריות בכל העולם. באופן טבעי כלב או חתול מבוגרים יותר נמצאים בסיכון גבוה יותר לחלות ולכן עלות הביטוח שלהם גבוהה יותר.
                                            </p>
                                            <p>
                                                כדאי להירשם לביטוח מוקדם כשהמחיר נמוך וחיית המחמד בריאה. חשוב לא פחות לבטח גם חיית מחמד מבוגרת על מנת לוודא שיהיה מענה הולם למחלות ותאונות במידת הצורך.
                                            </p>
                                        </div>
                                    </TSAccordion>
                                    <TSAccordion title="איך מתבצע התשלום על הפוליסה של חיותא?">
                                        <div>
                                            <p>
                                                התשלום על הפוליסה של חיותא מתבצע בכל תחילת שנת ביטוח עבור שנת ביטוח אחת. אפשר לחלק אותו לתשלומים ואפשר לשלמו במלואו בתחילת השנה. את התשלום מומלץ לבצע באמצעות כרטיס אשראי כיוון והחלוקה לתשלומים מתבצעת ע"י חברת הביטוח ואינה תופסת מסגרת.
                                            </p>
                                        </div>
                                    </TSAccordion>
                                    <TSAccordion title="דרך איזו חברת ביטוח מוענק הכיסוי של חיותא?">
                                        <div>
                                            <p>
                                                הכיסוי הביטוחי בפוליסת חיותא הוא של שומרה, חברה לביטוח בע"מ מבית מנורה. שומרה מדורגת כבר 9 שנים כחברת הביטוח הטובה בישראל ע"פ סקר לשכת סוכני הביטוח, נובמבר 2020.
                                            </p>
                                        </div>
                                    </TSAccordion>
                                </TSAccordion>
                            </Element>
                            <div className="spacer"></div>
                        </div>

                    </div>
                    <button className={"purchaseButton" + (!selectedPlan ? " disabled" : "")} onClick={handlePurchase}>המשך לרכישה</button>
                </div>
            </Slide>
        </TSPage>
    );
}

export { OffersPage }