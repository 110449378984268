class Utils {
    static apiCall(data) {
        return fetch("/ChatAPI.cht", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
    static displaySimpleMsg(dispatcher, content, onOk) {
        const handleOk = (e) => {
            dispatcher({ action: "closeOverlay", params: "msg" });
            if (onOk)
                onOk();
        };
        dispatcher({
            action: "openOverlay", params: {
                overlay: "msg", params: {
                    children: [
                        <div key="txt">{content}</div>,
                        <div key="btns" className="dialog-buttons">
                            <button key="btn1" onClick={handleOk}>אישור</button>
                        </div>
                    ]
                }
            }
        });
    }

    static displaySimpleYNQuestion(dispatcher, { content, onYes, onNo, yesText, noText }) {
        const handleBtn = (action, e) => {
            dispatcher({ action: "closeOverlay", params: "msg" });
            if (action)
                action();
        };
        dispatcher({
            action: "openOverlay", params: {
                overlay: "msg", params: {
                    children: [
                        <div key="txt">{content}</div>,
                        <div key="btns" className="dialog-buttons">
                            <button key="btnNo" onClick={handleBtn.bind(this, onNo)}>{noText || "לא"}</button>
                            <button key="btnYes" onClick={handleBtn.bind(this, onYes)}>{yesText || "כן"}</button>
                        </div>
                    ]
                }
            }
        });
    }

    static ga_event(eventName, eventData) {
        try {
            if (window.gtag)
                window.gtag('event', eventName, eventData || {});
        } catch (ex) {
            console.error(ex);
        }
    }
}

export { Utils }