import { useCallback, useEffect } from 'react';
import { useApp } from '../app-context';

import { ChatService } from '../services/ChatService';
import { css } from 'glamor';
import { ChatMessage } from '../components/ChatMessage';
import { animateScroll } from 'react-scroll';
import debounce from 'lodash.debounce';

import '../components/ChatMessages.scss'

function ChatMessages({ messages }) {
    const [state, dispatcher] = useApp();

    const actualScroller = () => {
        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                animateScroll.scrollToBottom({
                    duration: 300,
                    smooth: true,
                    containerId: 'chat-msg-scroller'
                });
            });
        });
    }

    const scroller = useCallback(debounce(actualScroller, 300), []);

    useEffect(() => {
        scroller();
    }, [messages]);

    const handleEdit = function (e) {
        let actualEdit = (e) => {
            let lastInteraction = state.curInteraction;
            ChatService.rewindScript(state._token)
                .then(data => {
                    dispatcher({ action: "loggedIn", params: data });
                    dispatcher({ action: "closeOverlay", params: "msg" });
                    (lastInteraction?.msgs || []).forEach(msg => {
                        if (msg.type === "screen" && msg.screen)
                            dispatcher({ action: "closeOverlay", params: msg.screen });
                    });
                })
                .catch(ex => {
                    console.error(ex);
                    //TODO: handle this
                });
        };
        let cancelEdit = (e) => {
            dispatcher({ action: "closeOverlay", params: "msg" });
        };

        dispatcher({ action: "updateOptionsSelector", params: undefined });
        dispatcher({
            action: "openOverlay", params: {
                overlay: "msg", params: {
                    children: [
                        <div key="txt">לחיצה על אישור תמחק את תשובתך האחרונה בלבד ותאפשר לך לענות עליה מחדש</div>,
                        <div key="btns" className="dialog-buttons">
                            <button key="btn1" onClick={actualEdit}>אישור</button>
                            <button key="btn2" onClick={cancelEdit}>ביטול</button>
                        </div>
                    ]
                }
            }
        });
    }

    let css_rule = css({
        height: '100%',
        paddingRight: state.uiParams?.scrollSize?.w + "px",
        width: '100%',
        boxSizing: 'content-box'
    });

    let inMessages = messages || state.messages;
    let renderedMessages = [];

    var i;
    var lastInMsg = {};
    var lastEditable = undefined;

    for (i = inMessages.length - 1; i >= 0; i--) {
        let msg = inMessages[i];
        if (msg.dir === "in" && !lastInMsg.id) lastInMsg = msg;
        var needAvatar = msg.dir === "in" && (i === inMessages.length - 1 || (i < inMessages.length - 1 && inMessages[i + 1].dir === "out"));
        if (msg.dir === "out" && !lastEditable) {
            lastEditable = <ChatMessage key={"m" + i} msg={msg} avatar={needAvatar ? msg.avatarImg || "" : undefined} onEdit={handleEdit.bind(this,)} scroller={scroller} />;
            renderedMessages.push(lastEditable);
        } else {
            renderedMessages.push(<ChatMessage key={"m" + i} msg={msg} avatar={needAvatar ? msg.avatarImg || "" : undefined} scroller={scroller} />);
        }
    }

    renderedMessages.reverse();

    i = inMessages.length;

    var lastMsg = inMessages.length > 0 ? inMessages[inMessages.length - 1] : undefined;

    //error msg
    if (state.chat_error) {
        renderedMessages.push(<ChatMessage key={"m" + i++} msg={state.chat_error} avatar={state.chat_error.avatarImg || ""} scroller={scroller} />);
        lastMsg = state.chat_error;
    }

    if (state.pendingAnswer) {
        lastMsg = state.pendingAnswer;
        renderedMessages.push(<ChatMessage key={"m" + i++} msg={lastMsg} scroller={scroller} />);
    }

    //special screen
    if (lastMsg && lastMsg.type === "screen" && lastMsg.screen) {
        requestAnimationFrame(() => {
            let pd = { plans: (lastMsg.data || {})["policy.plans"] || [] };

            pd.onBack = handleEdit;
            if (!(state?._openOverlays || {})[lastMsg.screen])
                dispatcher({ action: 'openOverlay', params: { overlay: lastMsg.screen, params: { hasHeader: true, props: pd } } });
        });
    } else {
        //answers
        if (lastMsg && lastMsg.dir === "in" && lastMsg.answers) {
            if ((lastMsg.answers.buttons || []).length > 0) {
                let msg = { ...lastMsg.answers };
                msg.type = msg.type || "buttons";
                renderedMessages.push(<ChatMessage key={"m" + i++} msg={msg} scroller={scroller} />);
            }
        }
    }

    if (state.typing && !state.chat_error) {
        renderedMessages.push(<ChatMessage key={"m" + i++} msg={{ dir: "in", type: "typing" }} avatar={(lastInMsg || {}).avatarImg || ''} scroller={scroller} />);
    }

    return (
        <div className="chat-msg-container" style={{ paddingBottom: state.optionsSelector ? "10.8em" : "2.8em" }}>
            <div id="chat-msg-scroller" className={css_rule.toString()}>
                {renderedMessages}
            </div>
        </div>
    );
    /*<div className="chat-msg-scroller" style={{marginRight: state.uiParams?.scrollSize?.w || 0}}>
            <div className="chat-msg-scroller-content">
            </div>
            </div>*/
}

export { ChatMessages }