import { useState } from 'react';

import { Slide } from 'react-awesome-reveal';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { TSPage } from '../components/commons';

import './BankDetailsPage.scss';

const BankDetailsPage = function ({ name, onBack, data, btn }) {
    const [state, dispatcher] = useApp();

    const [stateDetails, setStateDetails] = useState({});
    const [stateErrors, setStateErrors] = useState({});
    const [stateBusy, setStateBusy] = useState(false);

    const handleBack = (e) => {
        if (stateBusy) return;

        if (onBack)
            onBack(true, e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (stateBusy) return;

        if (!validateFields()) {
            return;
        }

        let answer = {
            display: "התקבלו פרטי חשבון",
            value: stateDetails,
            id: btn?.id,
            field: btn?.field
        }

        setStateBusy(true);
        ChatService.processAnswer(dispatcher, state, answer)
            .then(() => {
                dispatcher({ action: 'closeOverlay', params: 'bankAccount' });
            })
            .catch(ex => {
                alert(ex);
            }).finally(() => {
                setStateBusy(false);
            });
    };

    const validateFields = function () {
        var errors = {};
        if (!stateDetails.bank)
            errors.bank = "חובה להזין את שם הבנק";

        if (!stateDetails.branch)
            errors.branch = "יש להזין את מספר הסניף";

        if (isNaN(stateDetails.branch * 1))
            errors.branch = "על הסניף להיות מספר בלבד";

        if (!stateDetails.account)
            errors.account = "יש להזין את מספר החשבון";

        if (isNaN(stateDetails.account * 1))
            errors.account = "על החשבון להיות מספר בלבד";

        setStateErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onFieldChange = function (id, e) {
        const cs = { ...stateDetails };
        cs[id] = e.target.value;
        setStateDetails(cs);
    }

    const cb = (_inview, ioe) => { ioe.target.querySelector(".container").classList.add("on"); }

    data = data || {};
    return (
        <TSPage name={name}>
            <Slide direction="up" duration="500" onVisibilityChange={cb}>
                <div className="container on">

                    <div className="header">
                        <button className="btn right" onClick={handleBack}>
                            <img src="img/right-arrow.svg" alt="back" />
                        </button>
                        <div className="title">
                            פרטי חשבון בנק
                        </div>
                    </div>

                    <div className="content" style={{ marginLeft: -state?.uiParams?.scrollSize?.w + "px" }}>
                        <div className="top">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <label>שם בנק</label>
                                    <select name="bank" onChange={onFieldChange.bind(this, "bank")} className={stateErrors.bank && "error"}>
                                        <option value="">בחר</option>
                                        <option value="1">בנק  יורו</option>
                                        <option value="26">בנק U</option>
                                        <option value="14">בנק אוצר החייל</option>
                                        <option value="13">בנק איגוד</option>
                                        <option value="11">בנק דיסקונט</option>
                                        <option value="9">בנק הדואר</option>
                                        <option value="18">בנק וואן זירו</option>
                                        <option value="19">בנק החקלאות לישראל</option>
                                        <option value="8">בנק הספנות לישראל</option>
                                        <option value="12">בנק הפועלים</option>
                                        <option value="4">בנק יהב לעובדי מדינה</option>
                                        <option value="54">בנק ירושלים</option>
                                        <option value="10">בנק לאומי/הישיר הראשון</option>
                                        <option value="7">בנק לפיתוח התעשייה</option>
                                        <option value="20">בנק מזרחי-טפחות</option>
                                        <option value="46">בנק מסד</option>
                                        <option value="17">בנק מרכנתיל דיסקונט</option>
                                        <option value="47">בנק עולמי להשקעות</option>
                                        <option value="34">בנק ערבי ישראלי</option>
                                        <option value="35">בנק פולסקא/פאקאו</option>
                                        <option value="52">בנק פועלי אגודת ישראל</option>
                                        <option value="28">בנק קונטיננטל</option>
                                        <option value="48">בנק קופת העובד הלאומי לאשראי וחסכון בנתניה</option>
                                        <option value="31">הבנק הבינלאומי הראשון</option>
                                        <option value="30">הבנק למסחר</option>
                                    </select>
                                    <div className={"validationError " + (stateErrors.bank && "on")}>{stateErrors.bank}</div>
                                </div>
                                <div className="row double">
                                    <div className="part">
                                        <label>מספר סניף</label>
                                        <input type="text" name="branch" inputMode="numeric" onChange={onFieldChange.bind(this, "branch")} className={stateErrors.branch && "error"}></input>
                                        <div className={"validationError " + (stateErrors.branch && "on")}>{stateErrors.branch}</div>
                                    </div>
                                    <div className="part">
                                        <label>מספר חשבון</label>
                                        <input type="text" name="account" inputMode="numeric" onChange={onFieldChange.bind(this, "account")} className={stateErrors.account && "error"}></input>
                                        <div className={"validationError " + (stateErrors.account && "on")}>{stateErrors.account}</div>
                                    </div>
                                </div>
                            </form>
                            <div className="decoration">
                                <img className="bg" src="img/money-cat.svg" alt="" />
                            </div>

                        </div>
                    </div>
                    <div className="dialog-buttons">
                        <button onClick={handleSubmit}>אישור</button>
                        <button onClick={handleBack}>ביטול</button>
                    </div>
                </div>
            </Slide >
        </TSPage >
    );
}

export { BankDetailsPage }