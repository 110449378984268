import { useRef, useState } from 'react';

import { Slide } from 'react-awesome-reveal';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { TSPage } from '../components/commons';

import './TextAreaPage.scss';

const TextAreaPage = function ({ name, onBack, data, btn }) {
    const [state, dispatcher] = useApp();

    const textareaRef = useRef();

    const [stateText, setStateText] = useState("");
    const [stateBusy, setStateBusy] = useState(false);

    const handleClear = (e) => {
        if (stateBusy) return;

        setStateText("");
        textareaRef?.current?.focus();
    };

    const handleSubmit = (e) => {
        if (stateBusy || (stateText || "").trim().length === 0) return;

        let answer = {
            display: stateText.substring(0, 20) + "...",
            value: stateText,
            id: btn?.id,
            field: btn?.field
        }

        setStateBusy(true);
        ChatService.processAnswer(dispatcher, state, answer)
            .then(() => {
                dispatcher({ action: 'closeOverlay', params: 'textarea' });
            })
            .catch(ex => {
                alert(ex);
            }).finally(() => {
                setStateBusy(false);
            });

    };

    const handleTextChanged = (e) => {
        let v = e.target.value || "";
        if (btn?.maxLength * 1 > 0)
            v = v.substring(0, btn?.maxLength * 1);

        setStateText(v);
    };

    const handleBack = (e) => {
        if (stateBusy) return;

        if (onBack)
            onBack(true, e);
    };

    const cb = (_inview, ioe) => { ioe.target.querySelector(".container").classList.add("on"); }

    data = data || {};

    return (
        <TSPage name={name}>
            <Slide direction="up" duration="500" onVisibilityChange={cb}>
                <div className="container on">

                    <div className="header">
                        <button className="btn right" onClick={handleBack}>
                            <img src="img/right-arrow.svg" alt="back" />
                        </button>
                        <div className="title">
                            {btn?.title || "תאור"}
                        </div>
                    </div>

                    <div className="content">
                        <div className="top">
                            <textarea value={stateText} onChange={handleTextChanged} ref={textareaRef} />
                            <button className="clear-btn" onClick={handleClear}><img src="/img/icon-trash.svg" alt="clear" /></button>
                            {btn?.maxLength * 1 > 0 ?
                                <span className="typer">{stateText.length} / {btn?.maxLength * 1} </span>
                                : undefined}
                        </div>
                        <div className="dialog-buttons">
                            <button onClick={handleSubmit}>אישור</button>
                            <button onClick={handleBack}>ביטול</button>
                        </div>
                    </div>
                </div>
            </Slide>
        </TSPage>
    );
}

export { TextAreaPage }