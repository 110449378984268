import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

class Controller {
    constructor() {
        this.ui = {
            windowSize: {},
            emSize: {},
            scrollSize: {}
        };
        this.emSize = {};
        window.addEventListener("resize", this.onResize.bind(this));
        this.onResize();
    }

    onResize(e) {
        let w = window.innerWidth;
        let h = window.innerHeight;

        let needsRender = false;
        
        if (this.ui.windowSize.w !== w || this.ui.windowSize.h !== h) {
            //$("html").height(h);
            document.querySelector("body").style.fontSize = this.calculateFontSize(w, h) + "px"; //scrollTop(0);

            this.ui.windowSize = {w:w, h:h};
            needsRender = true;

            setTimeout(this.onResize.bind(this), 500);
        }

        needsRender = this.calculateEms() || needsRender;
        needsRender = this.calculateScrollbar() || needsRender;

        if (needsRender) {
            this.render();
            //TODO: scroll to bottom
        }
    }

    calculateFontSize(w,h) {
            var fontSize = w/18;
            
            if (fontSize > 28)
                fontSize = 28;

            return fontSize;
    }

    calculateEms() {
        let newSize = {
            w: document.getElementById("dummyEm").offsetWidth,
            h: document.getElementById("dummyEm").offsetHeight,
        };
        if (newSize.h !== this.ui.emSize.h || newSize.w !== this.ui.emSize.w) {
            this.ui.emSize = newSize;
            return true;
        }
        return false;
    }

    calculateScrollbar() {
        let newSize = {
            w: document.getElementById("dummyScroll").offsetWidth - document.querySelector("#dummyScroll .inner").offsetWidth,
            h: document.getElementById("dummyScroll").offsetHeight - document.querySelector("#dummyScroll .inner").offsetHeight
        };
        if (newSize.h !== this.ui.scrollSize.h || newSize.w !== this.ui.scrollSize.w) {
            this.ui.scrollSize = newSize;
            return true;
        }
        return false;
    }
            
    /*
    var f = h/w;
    if (f >= 1.6)
        $("body").addClass("longScreen").removeClass("shortScreen");
    else if (f < 1.5)
        $("body").removeClass("longScreen").addClass("shortScreen");
    else
        $("body").removeClass("longScreen shortScreen");
    
    $(".fullscreen").width(w).height(h);
    */

    test() {
        console.log("this is a test");
    }

    render() {
        ReactDOM.render(
            <React.StrictMode>
              <App uiParams={this.ui}/>
            </React.StrictMode>,
            document.getElementById('root')
          );
    }
}

const ControllerInst = new Controller();

export {ControllerInst}