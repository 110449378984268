import { useState, useRef, useEffect } from 'react';

import { Slide } from 'react-awesome-reveal';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { Utils } from "../Utils";
import { TSPage } from '../components/commons';

import './ClaimDocsPage.scss';
import { animateScroll } from 'react-scroll';

const ClaimDoc = function ({ key, docDef, isOpen, validate, onSelected, onFileSelected, isUploading, onFileDeleteRequest }) {
    const [, dispatcher] = useApp();
    const content = useRef(null);
    const selectedFile = useRef(null);

    const [stateHeight, setStateHeight] = useState();

    useEffect(() => {
        if (stateHeight !== content.current?.scrollHeight)
            setStateHeight(content.current.scrollHeight);
    });

    const handleSelectFile = (e) => {
        selectedFile.current?.click();
    };

    const handleWaitLoaded = (e) => {
        setStateHeight(content.current.scrollHeight);
    };

    const handleTooltipClicked = (exp, e) => {
        e.stopPropagation();
        Utils.displaySimpleMsg(dispatcher, exp);
    };

    let files = (docDef.files || []).map((v, k) => {
        let sizeFormatter = (size) => {
            if (!size || isNaN(size * 1))
                return "";

            size = size * 1;
            if (size > 1024 * 1024)
                return (Math.round(size * 10 / 1024 / 1024) / 10) + " MB";
            else if (size > 1024)
                return (Math.round(size * 10 / 1024) / 10) + " KB";
            else
                return size + "B";
        };

        return (
            <div key={"file" + k} className="uploadedFile">
                <div className="icon">
                    <img src={"/img/claims/files/" + v.ext + ".svg"} alt={v.ext} />
                </div>
                <div className="name">
                    {v.originalFilename}
                    <div className="size">{sizeFormatter(v.fileSize)}</div>
                </div>
                <div className="delete">
                    <button onClick={onFileDeleteRequest ? onFileDeleteRequest.bind(this, docDef, v) : undefined}>
                        <img src={"/img/claims/delete.svg"} alt="delete" />
                    </button>
                </div>
            </div>
        )
    });

    return (
        <div key={key} className={"doc" + (isOpen ? " open" : "")}>
            <div className="header" onClick={onSelected?.bind(this, docDef)}>
                {
                    validate ?
                        <img src={"/img/icon-" + (!docDef.optional && (docDef.files?.length || 0) < 1 ? "err" : "check") + ".svg"} className="validation" alt="" />
                        :
                        undefined
                }
                <h3>{docDef.name} {docDef.optional ? <span>(אופציונאלי)</span> : undefined}</h3>
                {docDef.exp ?
                    <img src="/img/icon-question-gray.svg" className="icon" onClick={handleTooltipClicked.bind(this, docDef.exp)} alt="explain" title={docDef.exp} />
                    :
                    undefined
                }
                <div className="exp">{docDef.desc}</div>
            </div>
            <div className="files" ref={content} style={{ maxHeight: isOpen ? (stateHeight ? stateHeight + "px" : undefined) : "0px" }}>
                <div className="uploaded">
                    {files}
                </div>

                {
                    isUploading ?
                        <div className="waiting">
                            <img src="/img/loading.svg" className="slow-rotate reverse" alt="uploading..." onLoad={handleWaitLoaded} />
                        </div>
                        : [
                            <input key="hidFile" hidden type="file" ref={selectedFile} onChange={onFileSelected?.bind(this, docDef)} />,
                            <button key="upbtn" className="uploadBtn" onClick={handleSelectFile}>
                                <img src="/img/upload.svg" alt="" />
                                <div className="text">העלה מסמך</div>
                            </button>
                        ]
                }
            </div>
        </div>
    );
}

const ClaimDocsPage = function ({ name, onBack, data, btn }) {
    const [state, dispatcher] = useApp();

    const [stateDocs, setStateDocs] = useState(data["claim.files"] || [
        { type: "invoice", optional: false, files: [], name: "חשבוניות", desc: "צרף חשבוניות רלוונטיות לטיפול הרפואי" },
        { type: "visitsum", optional: false, files: [], name: "סיכום ביקור", desc: "צרף סיכומי ביקור אצל הוטרינר/ים" },
        { type: "bank", optional: false, files: [], name: "אישור ניהול חשבון", desc: "צרף שיק מבוטל או אישור ניהול חשבון" }
    ]);
    const [stateUploading, setStateUploading] = useState({});
    const [stateOpenDoc, setStateOpenDoc] = useState(stateDocs[0].type);
    const [stateSubmitReq, setStateSubmitReq] = useState(false);
    const [stateSubmitErr, setStateSubmitErr] = useState("");

    const handleSubmit = (e) => {
        if (Object.keys(stateUploading).length > 0)
            return;

        setStateSubmitReq(true);
        setStateOpenDoc("");
        setStateSubmitErr("");
        let problemArr = stateDocs.filter(v => !v.optional && (v.files?.length || 0) < 1);
        if (problemArr.length < 1) {
            let fileIds = [];
            stateDocs.forEach((v, k) => {
                (v.files || []).forEach(file => {
                    fileIds.push(file.fileId);
                });
            });
            let answer = {
                display: `התקבלו ${fileIds.length} מסמכי תביעה`,
                value: fileIds,
                id: btn?.id,
                field: btn?.field
            }
            ChatService.processAnswer(dispatcher, state, answer)
                .then(() => {
                    dispatcher({ action: 'closeOverlay', params: 'claim_docs' });
                });
        } else if (problemArr.length === 1) {
            setStateSubmitErr(`חסר מסמך "${problemArr[0].name}" יש להעלות את המסמך ואז להמשיך להגשת התביעה`);
            animateScroll.scrollToBottom({
                smooth: true,
                containerId: 'files-container'
            });
        } else {
            setStateSubmitErr(`חסרים המסמכים הבאים: ${problemArr.map(v => v.name).join(", ")} יש להעלות את המסמכים החסרים ואז להמשיך להגשת התביעה`);
            animateScroll.scrollToBottom({
                smooth: true,
                containerId: 'files-container'
            });
        }
    };

    const handleBack = (e) => {
        if (onBack)
            onBack(true, e);
    };

    const handleSelectDoc = (docDef, e) => {
        if (docDef.type !== stateOpenDoc)
            setStateOpenDoc(docDef.type);
    };

    const markUploading = (type, toggle) => {
        let uploading = { ...stateUploading };
        if (toggle)
            uploading[type] = true;
        else
            delete uploading[type];

        setStateUploading(uploading);
    };

    const handleFileUpload = (docDef, e) => {
        if (stateUploading[docDef.type])
            return;

        setStateSubmitReq(false);
        markUploading(docDef.type, true);

        ChatService.uploadFile(dispatcher, state, e.target.files[0], { id: btn?.id, fileDef: docDef })
            .then(function (opt) {
                console.log("then", arguments);
                let files = [...docDef.files];
                files.push(opt.data);
                let doc = { ...docDef };
                doc.files = files;
                let sDocs = [...stateDocs].map(v => v.type === doc.type ? doc : v);
                setStateDocs(sDocs);
            })
            .catch(function () {
                console.log("catch", arguments);
            })
            .finally(function () {
                console.log("finally", arguments);

                markUploading(docDef.type, false);
            });
    };

    const handleFileDelete = (docDef, file, e) => {
        if (stateUploading[docDef.type])
            return;

        setStateSubmitReq(false);
        Utils.displaySimpleYNQuestion(dispatcher, {
            content: `בטוח שברצונך למחוק את הקובץ ${file.originalFilename}?`,
            onYes: () => {
                markUploading(docDef.type, true);
                ChatService.deleteUploadedFile(dispatcher, state, docDef.type, file.fileId)
                    .then(function (opt) {
                        console.log("then", arguments);
                        let files = [...docDef.files].filter(v => v.fileId !== file.fileId);
                        let doc = { ...docDef };
                        doc.files = files;
                        let sDocs = [...stateDocs].map(v => v.type === doc.type ? doc : v);
                        setStateDocs(sDocs);
                    })
                    .catch(function () {
                        console.log("catch", arguments);
                    })
                    .finally(function () {
                        console.log("finally", arguments);
                        markUploading(docDef.type, false);
                    });
            }
        })
    };

    const cb = (_inview, ioe) => { ioe.target.querySelector(".container").classList.add("on"); }

    data = data || {};

    return (
        <TSPage name={name}>
            <Slide direction="up" duration="500" onVisibilityChange={cb}>
                <div className="container on">

                    <div className="header">
                        <button className="btn right" onClick={handleBack}>
                            <img src="img/right-arrow.svg" alt="back" />
                        </button>
                        <div className="title">
                            הגשת מסמכים
                        </div>
                    </div>

                    <div id="files-container" className="content" style={{ marginLeft: -state?.uiParams?.scrollSize?.w + "px" }}>
                        <div className="box">
                            <h2>על מנת להגיש את התביעה עליך לצרף מספר מסמכים</h2>
                            <p>תוכל לחזור למסך זה ולהשלים את הקבצים החסרים בכל רגע נתון</p>
                            <ul>
                                <li>צירוף המסמכים מבטיח קיצור זמני טיפול</li>
                                <li>במידת הצורך ניתן לצרף יותר ממסמך אחד לכל שלב</li>
                                <li>ניתן לצלם מסמכים במצלמה של הטלפון</li>
                                <li>ניתן לצרף מסמכים מסוג: וורד, Jpeg, PNG, PDF</li>
                            </ul>
                        </div>

                        <div className="docs-container">
                            {
                                stateDocs.map(v => ClaimDoc({
                                    key: v.type,
                                    docDef: v,
                                    isUploading: stateUploading[v.type],
                                    isOpen: v.type === stateOpenDoc,
                                    validate: stateSubmitReq,
                                    onSelected: handleSelectDoc,
                                    onFileSelected: handleFileUpload,
                                    onFileDeleteRequest: handleFileDelete
                                }))
                            }
                        </div>

                        <div className="submit-warning">
                            {stateSubmitErr}
                        </div>
                        <button type="submit" className="continueButton" onClick={handleSubmit}>המשך להגשת תביעה</button>
                    </div>
                </div>
            </Slide>
        </TSPage>
    );
}

export { ClaimDocsPage }