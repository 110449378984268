import { useState } from 'react';

import { Slide } from 'react-awesome-reveal';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { TSPage } from '../components/commons';

import './BitDetailsPage.scss';

const BitDetailsPage = function ({ name, onBack, data, btn }) {
    const [state, dispatcher] = useApp();

    const [stateDetails, setStateDetails] = useState({
        ...data?.["claim.policyDetails"],
        customerPhone: data?.["claim.policyDetails"]?.customerPhone?.replace("+972", "0")
    });
    const [stateErrors, setStateErrors] = useState({});
    const [stateBusy, setStateBusy] = useState(false);

    const handleBack = (e) => {
        if (stateBusy) return;

        if (onBack)
            onBack(true, e);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (stateBusy) return;

        if (!validateFields()) {
            return;
        }

        let answer = {
            display: "התקבלו פרטי חשבון ביט",
            value: stateDetails,
            id: btn?.id,
            field: btn?.field
        }

        setStateBusy(true);
        ChatService.processAnswer(dispatcher, state, answer)
            .then(() => {
                dispatcher({ action: 'closeOverlay', params: 'bitAccount' });
            })
            .catch(ex => {
                alert(ex);
            }).finally(() => {
                setStateBusy(false);
            });
    };

    const validateFields = function () {
        var errors = {};
        if (!stateDetails.customerFirstName)
            errors.customerFirstName = "יש להזין שם פרטי";

        if (!stateDetails.customerLastName)
            errors.customerLastName = "יש להזין שם משפחה";

        if (!stateDetails.customerPhone)
            errors.customerPhone = "יש להזין את מספר הטלפון";

        if (!stateDetails.customerPId)
            errors.bitcustomerPId = "יש להזין את מספר תעודת הזיהוי";

        setStateErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const onFieldChange = function (id, e) {
        const cs = { ...stateDetails };
        cs[id] = e.target.value;
        setStateDetails(cs);
    }

    const cb = (_inview, ioe) => { ioe.target.querySelector(".container").classList.add("on"); }

    data = data || {};

    return (
        <TSPage name={name}>
            <Slide direction="up" duration="500" onVisibilityChange={cb}>
                <div className="container on">

                    <div className="header">
                        <button className="btn right" onClick={handleBack}>
                            <img src="img/right-arrow.svg" alt="back" />
                        </button>
                        <div className="title">
                            פרטי חשבון ביט להעברת התשלום
                        </div>
                    </div>

                    <div className="content" style={{ marginLeft: -state?.uiParams?.scrollSize?.w + "px" }}>
                        <div className="top">
                            <form onSubmit={handleSubmit}>
                                <div className="row double">
                                    <div className="part">
                                        <label>שם פרטי</label>
                                        <input type="text" name="customerFirstName" value={stateDetails.customerFirstName} onChange={onFieldChange.bind(this, "customerFirstName")} className={stateErrors.customerFirstName && "error"}></input>
                                        <div className={"validationError " + (stateErrors.customerFirstName && "on")}>{stateErrors.customerFirstName}</div>
                                    </div>
                                    <div className="part">
                                        <label>שם משפחה</label>
                                        <input type="text" name="customerLastName" value={stateDetails.customerLastName} onChange={onFieldChange.bind(this, "customerLastName")} className={stateErrors.customerLastName && "error"}></input>
                                        <div className={"validationError " + (stateErrors.customerLastName && "on")}>{stateErrors.customerLastName}</div>
                                    </div>
                                </div>
                                <div className="row double">
                                    <div className="part">
                                        <label>מספר טלפון</label>
                                        <input type="tel" name="customerPhone" value={stateDetails.customerPhone} onChange={onFieldChange.bind(this, "customerPhone")} className={stateErrors.customerPhone && "error"}></input>
                                        <div className={"validationError " + (stateErrors.customerPhone && "on")}>{stateErrors.customerPhone}</div>
                                    </div>
                                    <div className="part">
                                        <label>מספר ת.ז</label>
                                        <input type="number" name="bitPid" value={stateDetails.customerPId} onChange={onFieldChange.bind(this, "customerPId")} className={stateErrors.customerPId && "error"}></input>
                                        <div className={"validationError " + (stateErrors.customerPId && "on")}>{stateErrors.customerPId}</div>
                                    </div>
                                </div>
                                <br />
                                <div className='txt'>נבקש לוודא שיש תאימות בין מספר הטלפון לת"ז.</div>
                                <br />
                                <div className='txt'>במידה והתביעה תאושר יש לשים לב שההעברה</div>
                                <div className='txt'>נעשית ע"ש "שומרה חברה לביטוח".</div>
                            </form>
                            <div className="decoration">
                                <img className="bg" src="img/money-cat.svg" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="dialog-buttons">
                        <button onClick={handleSubmit}>אישור</button>
                        <button onClick={handleBack}>ביטול</button>
                    </div>
                </div>
            </Slide >
        </TSPage >
    );
}

export { BitDetailsPage }