import { useEffect } from 'react';

import { Fade } from 'react-awesome-reveal';

import { useApp } from '../app-context';
import { ChatService } from '../services/ChatService'
import { TSPage } from '../components/commons';

import './OffersSignupPage.scss';
import { Utils } from '../Utils';

const OffersSignupPage = props => {
    const siteUrl = process.env.REACT_APP_SITE_URL || "https://chayuta.com";
    const signupUrl = `${siteUrl}/signup/`;
    const channel = new MessageChannel();

    const [state, dispatcher] = useApp();

    useEffect(()=>{
        dispatcher({ action: "analyticsEvent", params: {"category": "signup", "event": "offer_opened"} });
    }, []);

    useEffect(() => {
        const iframe = document.querySelector('iframe');

        const onMessage = e => {
            const data = e.data;
            if (data.action === 'back')
                props?.onBack(true);
            if (data.action === 'payment')
                handlePlanPurchase(data.plan);
        }

        const onLoad = () => {
            channel.port1.onmessage = onMessage;
            iframe.contentWindow.postMessage(props?.data, signupUrl, [
                channel.port2,
            ]);
        }

        iframe.addEventListener("load", onLoad);
    }, []);

    const handlePlanPurchase = plan => {
        Utils.ga_event("offer_purchase_click", {});
        if (!plan) {
            Utils.ga_event("offer_purchase_err", { err: "NO_PLAN" });
            return;
        }
        let display = "פוליסת " +
            plan.title +
            " ב- ₪" + (plan.price / 100).toLocaleString() +
            " לשנה או ₪" + plan.monthlyPrice / 100 +
            " לחודש";
        const discountDesc = props.data?.["policy.discount"]?.desc;
        if (discountDesc)
            display += ".<br/>" + discountDesc;
        const answer = {
            display,
            value: `${plan.id}:${plan.copayment}:${plan.thirdParty}`,
            id: props?.btn?.id,
            field: props?.btn?.field,
            action: props?.btn?.action,
            actionData: props?.btn?.actionData
        }
        ChatService.processAnswer(dispatcher, state, answer, "html");
        dispatcher({ action: 'closeOverlay', params: 'offersSignup' });
    };

    return (
        <TSPage name={props.name}>
            <Fade duration="500" delay="150">
                <div className="container on">
                    <iframe src={signupUrl} width="100%" height="100%"></iframe>
                </div>
            </Fade>
        </TSPage>
    );
}

export { OffersSignupPage };