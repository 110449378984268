import { useApp } from '../app-context'
import { Utils } from '../Utils';
import '../components/ChatHeader.scss'

function ChatHeader({ extra }) {
    const [state, dispatcher] = useApp();

    const handleOpenMenu = function (e) {
        e.preventDefault();
        dispatcher({ action: 'openOverlay', params: { overlay: "chatMenu" } });
    };

    const handleClose = (e) => {
        e.preventDefault();
        Utils.displaySimpleYNQuestion(dispatcher, {
            content: "בטוח שברצונך לסגור ולעבור לאתר חיותא?",
            onYes: () => {
                dispatcher({ action: 'closeChat', params: {} });
            }
        });
    };

    let emptyHeader = false;
    Object.entries(state._openOverlays || {}).forEach(([, pageParams]) => {
        if (pageParams?.hasHeader)
            emptyHeader = true;
    });

    return (
        emptyHeader ?
            <div className="chat-header" />
            :
            <div className="chat-header">
                <button className="menu" onClick={handleOpenMenu}>
                    <img src="img/dots-menu.svg" alt="menu" />
                </button>
                <img className="logo" src="img/logo-white.svg" alt="logo" />
                <div className="extra">{state.title}</div>
                <button className="close" onClick={handleClose}>
                    <img src="img/close.svg" alt="back" />
                </button>
            </div>
    )
}

export { ChatHeader }